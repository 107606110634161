
function scroll (id, offset) {
  const yOffset = offset;
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({top: y, behavior: "smooth"});
  // const element = document.getElementById(id);
  // const y = element.getBoundingClientRect().top + window.pageYOffset;
  // window.scrollTo({top: y, behavior: 'smooth'});
};

function link (value, path, sharedClasses) {
  if (path == null) {
    return (
      <span className={sharedClasses.link}>{value}</span>
    );
  } else {
    return (
      <a className={sharedClasses.link} href={path}>{value}</a>
    );
  }
};

function inlineTitle (value, sharedClasses) {
  return (
    <span className={sharedClasses.inlineTitle}>{value}</span>
  );
};

function bold (value, sharedClasses) {
  return (
    <span className={sharedClasses.bold}>{value}</span>
  );
};

function code (value, sharedClasses) {
  return (
    <span className={sharedClasses.code}>{value}</span>
  );
};

export { scroll, link, inlineTitle, bold, code };

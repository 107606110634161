// react components
import React, { useEffect } from "react"
import { HashLink as Link } from "react-router-hash-link";

// material-ui components
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

// material-ui icons
import MenuIcon from "@material-ui/icons/Menu";

// components
import ParallaxBlock from "./ParallaxBlock";
import { scroll }  from "./Utilities";

// styles
import useSharedStyles from "./SharedStyles";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: theme.sideBarWidth,
      zIndex: 1,
      flexShrink: 0,
    },
  },
  drawerContainer: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  drawerPaper: {
    width: theme.sideBarWidth,
    marginTop: theme.appBarHeight,
    backgroundColor: theme.palette.custom.background,
    border: "none",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
      marginTop: 0,
    },
    // borderRightStyle: "solid",
    // borderWidth: 1,
    // borderColor: theme.palette.custom.line,
  },
  menuButton: {
    position: "fixed",
    left: 0,
    top: 0,
    width: 75,
    height: 75,
    color: theme.palette.custom.primary,
    zIndex: theme.zIndex.drawer + 2,
    display: "none",
    zIndex: theme.zIndex.drawer + 15,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  toolbar: theme.mixins.toolbar,
  section: {
    // borderBottomStyle: "solid",
    // borderWidth: 1,
    // borderColor: "blue",
  },
  subsection: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  sectionText: {
    fontSize: 16,
    fontWeight: 600,
    padding: 0,
    lineHeight: 1.5,
    color: theme.palette.custom.primary
  },
  subsectionText: {
    fontSize: 14,
    padding: 0,
    lineHeight: 1.5,
  }
}));

export default function Sidebar (props) {

  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const { data } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  var drawer = null;
  if (data !== undefined && data !== null) {
    drawer =
    <div className={classes.drawerContainer}>
      <List>
        {data.map((item, i) => (
          <div>
            <ListItem className={item.level == 1 ? classes.section : classes.subsection} key={i} button onClick={() => {scroll(item.id, -75)}}>
              <ListItemText disableTypography={true}>
                <Typography className={item.level == 1 ? classes.sectionText : classes.subsectionText}>
                  {item.label}
                </Typography>
              </ListItemText>
            </ListItem>
          </div>
        ))}
      </List>
    </div>
  }

  return(
    <div>
      <div className={classes.menuButton}>
        <IconButton
          color="inherit"
          edge="start"
          onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
      </div>
      <div className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            open>
            {drawer}
          </Drawer>
        </Hidden>
      </div>
    </div>
  );
};

// React components
import React, { useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Route, Switch, useLocation } from "react-router-dom";

// material-ui components
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";

// components
import ParallaxBlock from './ParallaxBlock';
import Button from './Button';
import Sidebar from "./Sidebar";
import Content from "./Content";
import Footer from "./Footer";
import ScrollToTopButton from "./ScrollToTopButton";
import { scroll }  from "./Utilities";
import { databases } from "./DataDescriptions";

// styles
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useSharedStyles from "./SharedStyles";

// Google analytics
import ReactGA from "react-ga";

export default function DatabasesPage (props) {

  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("UA-234919487-1");
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const sidebarData = [
    { id: "IUROPA-Project", label: "Iuropa Project" , level: 1 },
    { id: "cjeu-database-platform", label: "The Court of Justice of the European Union (CJEU) Database Platform: Decisions and Decision-Makers" , level: 2 },
    { id: "cjeu-text-corpus", label: "The Court of Justice of the European Union (CJEU) Document Corpus" , level: 2 },
    { id: "European-Union", label: "European Union" , level: 1 },
    { id: "evoeu", label: "The Evolution of European Union Law (EvoEU) Database" , level: 2 },
    { id: "ecio", label: "The European Commission Internal Organization (ECIO) Database" , level: 2 },
    { id: "euip", label: "The European Union Infringement Procedure (EUIP) Database" , level: 2 },
    { id: "eusa", label: "The European Union State Aid (EUSA) Database" , level: 2 },
    { id: "eutr", label: "The European Union Technical Regulations (EUTR) Database" , level: 2 },
    { id: "eums", label: "The European Union Member States (EUMS) Database" , level: 2 },
    { id: "United-Nations", label: "United Nations" , level: 1 },
    { id: "ungadm", label: "The United Nations General Assembly (UNGA) Voting Records Database" , level: 2 },
    { id: "unia", label: "The United Nations Institutional Activity (UNIA) Database" , level: 2 },
    { id: "Canadian-Parliament", label: "Canadian Parliament" , level: 1 },
    { id: "cpp", label: "The Canadian Parliament Project (CPP) Database" , level: 2 },
    { id: "World-Cup", label: "World Cup" , level: 1 },
    { id: "world-cup-database", label: "The Fjelstul World Cup Database" , level: 2 },
  ];

  const sidebarItems = sidebarData.map((item, i) => {
    return <div className={item.level == 1 ? "personal-sidebar-section-text" : "personal-sidebar-subsection-text"} onClick={() => { scroll(item.id, -80) }}>
      {item.label}
    </div>
  });

  const sidebar =
  <div>
    <div className="personal-sidebar-header">
      Data
    </div>
    <div className="personal-sidebar-container">
      {sidebarItems}
    </div>
  </div>

  const MakeDatabaseDescription = (database, contact) => {

    // var button = <Button label="Learn More" link={"/data/" + database.url} />;
    // button = <Button label="Contact Me" externalLink="mailto: joshua.fjelstul@unige.ch" />

    return(
      <div id={database.url} className={database.class}>
        <div className="personal-block-title">
          The {database.long_name}
        </div>
        <div className="personal-chip-container">
          {database.author.map((author, i) => {
            return <Chip key={i} label={author} className="personal-chip"/>
          })}
        </div>
        <div className="personal-block-paragraph">
          {database.description}
        </div>
        <div className="personal-block-content-centered">
          <div className="personal-button-container">
            <a className="personal-button-outlined" href={database.button_link} onClick={(e) => ReactGA.pageview(database.button_link)}>
              {database.button_label}
            </a>
          </div>
        </div>
      </div>
    )
  }

  const contentItems =
  <div>

    <div id="IUROPA-Project">
      <div className="personal-block-color">
        IUROPA Project
      </div>
    </div>

    {MakeDatabaseDescription(databases.cjeuDatabasePlatform)}
    {MakeDatabaseDescription(databases.cjeuTextCorpus, true)}

    <div id="European-Union">
      <div className="personal-block-color">
        European Union
      </div>
    </div>

    {MakeDatabaseDescription(databases.evoeu)}
    {MakeDatabaseDescription(databases.ecio)}
    {MakeDatabaseDescription(databases.euip)}
    {MakeDatabaseDescription(databases.eusa)}
    {MakeDatabaseDescription(databases.eutr)}
    {MakeDatabaseDescription(databases.eums)}

    <div id="United-Nations">
      <div className="personal-block-color">
        United Nations
      </div>
    </div>

    {MakeDatabaseDescription(databases.ungaVotingRecords, true)}
    {MakeDatabaseDescription(databases.unia)}

    <div id="Canadian-Parliament">
      <div className="personal-block-color">
        Canadian Parliament
      </div>
    </div>

    {MakeDatabaseDescription(databases.cpp)}

    <div id="World-Cup">
      <div className="personal-block-color">
        World Cup
      </div>
    </div>

    {MakeDatabaseDescription(databases.worldCup)}
  </div>

  const content =
  <div className="personal-page-content-container">
    {contentItems}
    <Footer />
  </div>

  return (
    <div className="personal-page-container">
      {sidebar}
      {content}
    </div>
  );
};

// React components
import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default function Footer (props) {

  const { shadow } = props;

  const footerColumns =
  <div className="personal-footer-columns">
    <div className="personal-footer-column">
      <div className="personal-footer-heading">
        <Link className="personal-link-white" to={"/#about-me"}>About Me</Link>
      </div>
      <div className="personal-footer-break"></div>
      <div className="personal-footer-heading">
        <Link className="personal-link-white" to={"/#research"}>Research</Link>
      </div>
      <div className="personal-footer-item">
        <Link className="personal-link-white" to={"/research#publications"}>Publications</Link>
      </div>
      <div className="personal-footer-item">
        <Link className="personal-link-white" to={"/research#media"}>Media</Link>
      </div>
      <div className="personal-footer-item">
        <Link className="personal-link-white" to={"/research#working-papers"}>Working Papers</Link>
      </div>
      <div className="personal-footer-break"></div>
      <div className="personal-footer-heading">
        <Link className="personal-link-white" to={"/#teaching"}>Teaching</Link>
      </div>
      <div className="personal-footer-item">
        <Link className="personal-link-white" to={"/teaching#courses"}>Courses</Link>
      </div>
      <div className="personal-footer-item">
        <Link className="personal-link-white" to={"/teaching#workshops"}>Workshops</Link>
      </div>
      <div className="personal-footer-item">
        <Link className="personal-link-white" to={"/teaching#tutorials"}>Tutorials</Link>
      </div>
      <div className="personal-footer-break"></div>
    </div>
    <div className="personal-footer-column">
      <div className="personal-footer-heading">
        <Link className="personal-link-white" to={"/#data"}>Data</Link>
      </div>
      <div className="personal-footer-item">
        <Link className="personal-link-white" to={"/data#IUROPA-Project"}>The IUROPA CJEU Database</Link>
      </div>
      <div className="personal-footer-item">
        <Link className="personal-link-white" to={"/data#European-Union"}>European Union</Link>
      </div>
      <div className="personal-footer-item">
        <Link className="personal-link-white" to={"/data#United-Nations"}>United Nations</Link>
      </div>
      <div className="personal-footer-item">
        <Link className="personal-link-white" to={"/data#Canadian-Parliament"}>Canadian Parliament</Link>
      </div>
      <div className="personal-footer-item">
        <Link className="personal-link-white" to={"/data#World-Cup"}>World Cup</Link>
      </div>
      <div className="personal-footer-break"></div>
      {/* <div className="personal-footer-heading">
        <Link className="personal-link-white" to={"/#software"}>Software</Link>
      </div>
      <div className="personal-footer-item">
        <Link className="personal-link-white" to={"/software#R-packages"}>R Packages</Link>
      </div>
      <div className="personal-footer-item">
        <Link className="personal-link-white" to={"/software#REST-APIs"}>REST APIs</Link>
      </div> */}
      <div className="personal-footer-break"></div>
    </div>
  </div>;

  return (
    <div className= "personal-footer-container" style={{boxShadow: shadow ? "0px 0px 20px 5px rgba(0, 0, 0, 0.1)" : "none"}}>
      <div className="personal-footer-top-content">
        <Link className="personal-footer-title" to="/">
          Joshua C. Fjelstul, Ph.D.
        </Link>
      </div>
      <div className="personal-footer-middle-content">
        {footerColumns}
      </div>
      <div className="personal-footer-bottom-content">
        <div className="personal-footer-copyright">
          Designed and built by Joshua C. Fjelstul, Ph.D. © 2022.
        </div>
      </div>
    </div>
  );
}

// React components
import React, { useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Route, Switch, useLocation } from "react-router-dom";

// material-ui components
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";

// components
import ParallaxBlock from "./ParallaxBlock";
import Footer from "./Footer";
import { scroll }  from "./Utilities";

// Google analytics
import ReactGA from "react-ga";

export default function TeachingPage (props) {

  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("UA-234919487-1");
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

    const data = [
      {
        id: "courses",
        level: 1,
        label: "Courses",
        title: "Courses",
        class: "personal-block-color",
        image: "cover-image.jpg",
      },
      {
        id: "politics-of-the-european-union",
        level: 2,
        label: "Politics of the European Union (Undergraduate) — Washington University in St. Louis",
        title: "Politics of the European Union",
        tags: [
          "Washington University in St. Louis",
          "Undergraduate",
          "Spring 2020",
        ],
        description: [
          "This course is an introduction to the politics of the European Union (EU). The course will cover major aspects of EU politics, with a focus on the five main EU institutions — the European Council, the European Commission, the Council of the European Union, the European Parliament, and the Court of Justice of the European Union — and how these institutions create and implement EU policy. The course will focus on theory — models of politics that help us understand how the EU works and how EU law impacts EU citizens on a day-to-day basis. We will also discuss recent and current events in EU politics, including the European Sovereign Debt Crisis and Brexit."
        ],
        class: "personal-block-white",
        button_label: "Download syllabus",
        button_link: "Fjelstul-EU-syllabus.pdf",
      },
      {
        id: "international-political-economy",
        level: 2,
        label: "International Political Economy (Undergraduate) — Washington University in St. Louis",
        title: "International Political Economy",
        tags: [
          "Washington University in St. Louis",
          "Undergraduate",
          "Fall 2020",
        ],
        description: [
          "This course will be an introduction to the field of international political economy (IPE) — the study of how politics shapes the global economy. This course is first and foremost about politics, but it’ll also be interdisciplinary. It’ll be a mix of political science, history, economics, public policy, finance, and international law.",
          "We’ll cover all the major topics in IPE — including International trade, international investment, and international monetary policy — but we’re going to explore these topics through the lens of financial crises. We’re going to try to understand how the global economy works by taking a close look at when it doesn’t. We’ll talk about the Great Depression (1929-1939), the Latin American debt crisis (1979-1983), the Asian financial crisis (1997-1998), the global financial crisis (2007- 2008) and the Great Recession (2007-2009), the European Sovereign Debt Crisis (2009-2014), and the stock market crash caused by the COVID-19 pandemic.",
          "We’re going to pay particular attention to the role of national governments and international institutions (GATT, the WTO, the EU, and IMF, the World Bank, the EIB, ICSID, etc.) in governing and regulating the global economy — how they help to cause crises, how they help to prevent them, and how they help us to recover from them.",
        ],
        class: "personal-block-shaded",
        button_label: "Download syllabus",
        button_link: "Fjelstul-IPE-syllabus.pdf",
      },
      {
        id: "introduction-to-game-theory",
        level: 2,
        label: "Introduction to Game Theory (Graduate) — Emory University",
        title: "Introduction to Game Theory",
        tags: [
          "Emory University",
          "Undergraduate",
          "Spring 2016",
        ],
        description: [
          "The purpose of this course is to provide an introduction to game theory in political science. Students will be exposed to both cooperative and non-cooperative game theory. The focus of the course is on the Nash solution concept and its refinements. This material includes the Nash equilibrium, subgame-perfection, infinite horizon games, games of incomplete information and games of imperfect information.",
        ],
        class: "personal-block-white",
        button_label: "Contact me",
        button_link: "mailto: joshua.fjelstul@unige.ch",
      },
      {
        id: "workshops",
        level: 1,
        label: "Workshops",
        title: "Workshops",
        class: "personal-block-color",
        image: "cover-image.jpg",
      },
      {
        id: "IHEID-workshop-text-analysis",
        level: 2,
        label: "Social Science Methods for Lawyers: Quantitative Text Analysis — Graduate Institute (IHEID)",
        title :"Social Science Methods for Lawyers: Quantitative Text Analysis",
        tags: [
          "Graduate Institute (IHEID)",
          "Workshop",
          "Spring 2022",
          "Joshua C. Fjelstul",
        ],
        description: [
          "This workshop will provide an introduction to quantitative text analysis for legal research. First, we'll do a broad overview of text analysis methods and how they can be used to study international law. We'll do a survey of text analysis methods, identify the differences between supervised and unsupervised learning, and discuss how quantitative text analysis overlaps with statistics, machine learning (ML), and natural language processing (NLP). We'll talk about types of descriptive analysis, like frequency analysis and document similarity, and inferential analysis, like scaling and classification.",
          "Second, we'll learn some introductory text analysis methods for scaling and classifying legal documents, focusing on the intuition behind the methods and how to interpret the results. We'll cover unsupervised topic models for document classification (Latent Dirichlet allocation) and unsupervised methods for document scaling (Wordfish).",
          "Third, we'll learn how to clean and manipulate text data in R to get it ready for analysis. We'll learn how to write regular expressions — a syntax for matching patterns in text data — and how to implement them in R using stringr. Regular expressions are critical for cleaning and manipulating text data, including making dummy variables that capture the presence or absence of certain content in legal documents.",
          "Fourth, we'll learn how to implement the methods we've discussed in R to answer a real research question using the text of judgments from the Court of Justice of the European Union (CJEU). Using the full text of recent CJEU judgments, we'll extract the name of the judge-rapporteur (the judge who writes the judgment), classify the judgments using a topic model, scale the documents on the primary latent dimension and validate that dimension, and then make visualizations to communicate which judge-rapporteurs specialize in which policy areas and which are on the periphery of the latent dimension.",
        ],
        class: "personal-block-white",
        button_label: "See materials",
        button_link: "https://github.com/jfjelstul/IHEID-text-analysis-workshop",
      },
      {
        id: "IHEID-workshop-intro-to-R",
        level: 2,
        label: "Social Science Methods for Lawyers: Intro to R — Graduate Institute (IHEID)",
        title :"Social Science Methods for Lawyers: Intro to R",
        tags: [
          "Graduate Institute (IHEID)",
          "Workshop",
          "Spring 2022",
          "Joshua C. Fjelstul",
          "Chanya Punyakumpol",
          "Umut Yüksel",
        ],
        description: [
          "In this workshop, we'll introduce you to using R to study international law. First, we'll cover the basics of using R, including how to use RStudio (a popular interface for R), how to write and run R scripts, what the various types of objects are in R, and how to use and write functions in R.",
          "Second, we'll talk about how to clean and manipulate data in R. We'll use the tidyverse — a suite of open source packages for manipulating data that have become the standard in data science. We'll talk about how to use a programming technique called \"pipes\" to make your code more efficient and more readable, how to sort data, how to filter data, how to select variables from datasets, how to manipulate variables, how to collapse and expand datasets, how to merge datasets, and how to reshape datasets. We'll also go over tools for working with text variables (called \"strings\" in R), date variables, and categorical variables (called \"factors\" in R). We'll use real data on EU infringement proceedings to illustrate how using R makes all of these tasks easier, faster, more reliable, and more replicable than doing it by hand.",
          "Third, we'll introduce you to using R for data visualization using ggplot2 — the most powerful, and popular, tool for creating visualizations in data science. We'll show you how to prepare data for visualization, how make a variety of basic plots, and how to customize the look and feel of your plots. We'll also show you some examples of data visualizations made using ggplot2 related to the empirical study of international law.",
        ],
        class: "personal-block-shaded",
        button_label: "See materials",
        button_link: "https://github.com/jfjelstul/IHEID-R-workshop",
      },
      {
        id: "QTM-ECDS-workshop-manipulating-data",
        level: 2,
        label: "Intermediate R Workshop: Manipulating Data — Emory, QTM, ECDS",
        title :"Intermediate R Workshop: Manipulating Data",
        tags: [
          "Institute for Quantitiative Theory and Methods (QTM)",
          "Emory Center for Digital Scholarship (ECDS)",
          "Workshop",
          "Fall 2018",
          "Joshua C. Fjelstul",
        ],
        description: [
          "This workshop will focus on manipulating data in R. We will talk about best practices for working with multiple datasets at a time, combining datasets, and creating variables that use data from multiple datasets. The workshop will cover the tidyr, dplyr, plyr, and foreach packages.",
          "First, we will cover different ways of merging data frames (dplyr) and how to reshape data frames from wide format to long format (tidyr).",
          "Second, we will go over piping (dplyr), which is a way of chaining together operations (instead of nesting them). We can use pipes to pass the output of one operation to another operation without creating an intermediate object. We will use pipes to group observations and create variables based on those groups. We will also use it to collapse observations, aggregating the data.",
          "Third, we will cover ways to apply non-vectorized functions to variables in data frames, including for loops and split-apply-combine operations (foreach, plyr). We will also use loops to construct variables that use data from multiple observations or data frames. We will then talk about how to run code in parallel over multiple cores to speed up code that we want to run on large datasets and/or that uses computationally intensive operations. Finally, we will talk about how to organize data for making plots (ggplot2).",
        ],
        class: "personal-block-white",
        button_label: "See materials",
        button_link: "http://www.github.com/jfjelstul/QTM-ECDS-workshop-manipulating-data",
      },
      {
        id: "QTM-ECDS-workshop-strings",
        level: 2,
        label: "Intermediate R Workshop: Strings — Emory, QTM ECDS",
        title :"Intermediate R Workshop: Strings",
        tags: [
          "Institute for Quantitiative Theory and Methods (QTM)",
          "Emory Center for Digital Scholarship (ECDS)",
          "Workshop",
          "Fall 2018",
          "Spring 2019",
          "Joshua C. Fjelstul",
        ],
        description: [
          "This workshop will cover how to work with text data in R. We will start by talking about how to import common types of text files (.csv files, .txt files, and .html files) and how R organizes and stores text data. We will discuss the difference between strings and factors (the two basic classes of text-based data in R) and best practices for using each.",
          "Most of the workshop will focus on regular expressions, which is an incredibly useful tool for manipulating text data. We can use regular expressions to detect, replace, and extract text based on patterns. We will cover how to write regular expressions, including how to use meta-characters, sub-expressions, character classes, and repetition quantifiers. We will then talk about how to use regular expressions to create quantitative variables from raw text and to clean raw text for use in text analysis."
        ],
        class: "personal-block-shaded",
        button_label: "See materials",
        button_link: "http://www.github.com/jfjelstul/QTM-ECDS-workshop-strings",
      },
      {
        id: "QTM-ECDS-workshop-social-media",
        level: 2,
        label: "Intermediate R Workshop: Social Media — Emory, QTM, ECDS",
        title :"Intermediate R Workshop: Social Media",
        tags: [
          "Institute for Quantitiative Theory and Methods (QTM)",
          "Emory Center for Digital Scholarship (ECDS)",
          "Workshop",
          "Spring 2019",
          "John Bernau",
          "Joshua C. Fjelstul",
          "Rob O'Reilly"
        ],
        description: [
          "This workshop will cover how to use the Twitter API via R. We will scrape tweets using the API and use the data to construct network graphs and conduct text analysis, including topic modeling. We will also create a simple web app using shiny to visualize Twitter networks based on retweets, mentions, and replies.",
        ],
        class: "personal-block-white",
        button_label: "See materials",
        button_link: "http://www.github.com/jfjelstul/QTM-ECDS-workshop-social-media",
      },
      {
        id: "QTM-workshop-text-analysis",
        level: 2,
        label: "Quantitative Text Analysis for Social Scientists — Emory, QTM",
        title :"Quantitative Text Analysis for Social Scientists",
        tags: [
          "Institute for Quantitiative Theory and Methods (QTM)",
          "Workshop",
          "Spring 2016",
          "Spring 2017"
        ],
        description: [
          "This workshop will cover the basics of working with text data in R. We'll cover how to import and export text data in various formats, including CSV and HTML. We'll also cover the Twitter API. We'll explore how to implement a variety of text analsyis tools, including regular expressions, making corpuses and document term matrixes, cosine similarity, hierarchical clustering, K-means clustering, Latent Dirichlet Allocation, and more. We'll conclude with applied examples that use text analysis tools to create a map and a network visualization.",
        ],
        class: "personal-block-shaded",
        button_label: "See materials",
        button_link: "http://www.github.com/jfjelstul/QTM-workshop-text-analysis",
      },
      {
        id: "tutorials",
        level: 1,
        label: "Tutorials",
        title: "Tutorials",
        class: "personal-block-color",
        image: "cover-image.jpg",
      },
      {
        id: "regular-expressions",
        level: 2,
        label: "Regular Expressions in R",
        title: "Regular Expressions in R",
        tags: [
          "University of Geneva",
          "Tutorial",
          "Spring 2022",
        ],
        description: [
          "Regular expressions are a syntax for matching patterns in text. They allow us to detect, extract, replace, or remove text that satisfies a certain pattern, rather than just an exact string. Regular expressions are one of the most powerful tools you can learn for manipulating data and they’re essential to learn if you’re interested in quantitative text analysis. You can use regular expressions with any kind of text data, from books, to speeches, to legal documents, to tweets and social media posts. They’re useful for cleaning plain text, for extracting information from plain text to create string variables, and for detecting information in plain text to create dummy variables. In this tutorial, I’ll teach you everything you need to know about them using real-world examples."
        ],
        class: "personal-block-white",
        button_label: "See tutorial",
        button_link: "https://jfjelstul.github.io/regular-expressions-tutorial",
      },
    ];

  const sidebarItems = data.map((item, i) => {
    return <div className={item.level == 1 ? "personal-sidebar-section-text" : "personal-sidebar-subsection-text"} onClick={() => { scroll(item.id, -80) }}>
      {item.label}
    </div>
  });

  const sidebar =
  <div>
    <div className="personal-sidebar-header">
      Teaching
    </div>
    <div className="personal-sidebar-container">
      {sidebarItems}
    </div>
  </div>

  const contentItems = data.map((item, i) => {
    const title =
    <div className="personal-parallax-overlay">
      <div className="personal-parallax-title">
        {item.title}
      </div>
    </div>
    if (item.level == 1) {
      return <div id={item.id} key={i}>
        <ParallaxBlock path={item.image} content={title}/>
      </div>
    } else {
      return <div id={item.id} key={i} className={item.class}>
        <div className="personal-block-title">
          {item.title}
        </div>
        <div className="personal-chip-container">
          {item.tags.map((tag, i) => {
            return <Chip key={i} label={tag} className="personal-chip"/>
          })}
        </div>
        <div>
          {item.description.map((description, i) => {
            return <div className="personal-block-paragraph">{description}</div>
          })}
        </div>
        <div className="personal-block-content-centered">
          <div className="personal-button-container">
            <a className="personal-button-outlined" href={item.button_link} onClick={(e) => ReactGA.pageview(item.button_link)}>
              {item.button_label}
            </a>
          </div>
        </div>
      </div>
    }
  });

  const content =
  <div className="personal-page-content-container">
    <div className="personal-block-white">
      <div className="personal-block-title">
        My teaching interests and experience
      </div>
      <div className="personal-block-paragraph">
        My teaching interests include international organizations, international political economy (IPE), quantitative methods and data science (statistics, causal inference, machine learning), and game theory. In the spring of 2020, I taught <span className="personal-italic">The Politics of the European Union</span> at Washington University in St. Louis. In the fall of 2020, I taught <span className="personal-italic">International Political Economy</span>, focusing on the politics of financial crises. In the spring of 2016, I taught Emory University’s graduate-level <span className="personal-italic">Introduction to Game Theory</span> course (with Cliff Carrubba). I have also been a teaching assistant for <span className="italic">Introduction to International Relations</span> (undergraduate), <span className="personal-italic">Political Science Methods</span> (undergraduate), and <span className="italic">Introduction to Game Theory</span> (graduate).
      </div>
      <div className="personal-block-paragraph">
        I've completed the Teaching Assistant Training and Teaching Opportunity (TATTO) program conducted by the Laney Graduate School at Emory. TATTO is an innovative teacher training program designed to prepare Ph.D. students to teach undergraduate courses. This program consists of four components: a short summer course, departmental seminars, a teaching assistantship, and a teaching associateship (a co-teaching opportunity).
      </div>
      <div className="personal-block-paragraph">
        I also develop and teach data science workshops. I have designed and facilitated <span className="personal-code">R</span> workshops for the Institute for Quantitative Theory and Methods (QTM) and the Emory Center for Digital Scholarship (ECDS) at Emory University.
      </div>
      <div className="personal-block-paragraph">
        I have recently developed an <span className="personal-code">R</span> workshop on quantitative text analysis for social scientists and international lawyers in partnership with the Graduate Institute of International and Development Studies (IHEID) in Geneva. I plan to hold an online version of this workshop for the European Society of International Law (ESIL) in the fall of 2022.
      </div>
    </div>
    {contentItems}
    <Footer />
  </div>

  return (
    <div className="personal-page-container">
      {sidebar}
      {content}
    </div>
  );
};

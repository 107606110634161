// React components
import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

// utilities
// import { scroll } from "./Utilities";
import ScrollToTop from "./ScrollToTop";
import ArrowIcon from "@material-ui/icons/ArrowUpward";

// styles
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useSharedStyles from "./SharedStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "fixed",
    right: 50,
    bottom: 50,
    zIndex: 1000,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    transitionDuration: "0.25s",
  },
  toTop: {
    width: 47,
    height: 47,
    backgroundColor: "#4B94E6",
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  cv: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 22,
    paddingRight: 22,
    marginRight: 25,
    backgroundColor: theme.palette.custom.primary,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    borderRadius: 50,
    zIndex: 5000,
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Roboto",
    "&:hover": {
      cursor: "pointer",
    }
  }
}));

export default function ScrollToTopButton (props) {

  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 800){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 800){
      setShowScroll(false)
    }
  };
  window.addEventListener('scroll', checkScrollTop)

  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return(
    <div className={classes.container} style={{ opacity: showScroll ? 1 : 0 }}>
      <a className={classes.cv} href="Fjelstul-CV.pdf" download="Fjelstul-CV.pdf" style={{ textDecoration: "none" }}>
        Download My CV
      </a>
      <div className={classes.toTop} onClick={scrollTop}>
        <ArrowIcon style={{color: "#FFFFFF"}}/>
      </div>
    </div>
  );
};

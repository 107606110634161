// react components
import React, { useEffect } from "react"
import { HashLink as Link } from "react-router-hash-link";

// material-ui components
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

// material-ui icons
import MenuIcon from "@material-ui/icons/Menu";

// components
import ParallaxBlock from "./ParallaxBlock";
import ScrollToTopButton from "./ScrollToTopButton";
import { scroll }  from "./Utilities";

// styles
import useSharedStyles from "./SharedStyles";
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('md')]: {
      maxWidth: `calc(100vw - ${theme.sideBarWidth}px)`,
      marginLeft: theme.sideBarWidth,
    },
  },
}));

export default function Content (props) {

  const classes = useStyles();
  const sharedClasses = useSharedStyles();

  const { content } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return(
    <div className={classes.content}>
      { content }
    </div>
  );
};

// React components
import React, { Component, useEffect, useRef } from "react";
import { BrowserRouter, Route, Link, useHistory } from "react-router-dom";

// CSS
import './csm-app.css';

// API components
import Axios from "axios";

// mui components
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
// import Checkbox from "@mui/material/Checkbox";
// import Hidden from "@mui/material/Hidden";
// import Drawer from "@mui/material/Drawer";
// import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
// import Pagination from "@mui/material/Pagination";
// import Divider from "@mui/material/Divider";
import SelectArrow from "@mui/icons-material/KeyboardArrowDownRounded";
// import DownloadIcon from '@mui/icons-material/CloudDownload';
// import BookmarkIcon from '@mui/icons-material/BookmarkRounded';

// components
import Footer from "./Footer";

// mui icons
// import SaveIcon from "@mui/icons-material/Save";
// import MenuIcon from "@mui/icons-material/Menu";
// import LockIcon from "@mui/icons-material/Lock";
// import AddIcon from "@mui/icons-material/AddCircle";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import SearchIcon from "@mui/icons-material/Search";
// import FlagIcon from "@mui/icons-material/Flag";
// import NextIcon from "@mui/icons-material/SkipNext";
// import PreviousIcon from "@mui/icons-material/SkipPrevious";
// import CancelIcon from "@mui/icons-material/Cancel";
// import ResetIcon from "@mui/icons-material/RestartAlt";
// import UserIcon from "@mui/icons-material/AccountCircle";
// import UploadIcon from "@mui/icons-material/CloudUpload";
// import CorrectIcon from "@mui/icons-material/Check";
// import CheckedIcon from "@mui/icons-material/ThumbUp";
// import UndoIcon from "@mui/icons-material/Undo";
// import RedoIcon from "@mui/icons-material/Redo";
// import InfoIcon from "@mui/icons-material/Info";
// import ViewIcon from "@mui/icons-material/Article";
// import ClearIcon from "@mui/icons-material/Clear";

// components
import { scroll } from "./Utilities";

export default function Page (props) {

  const { token } = props;

  // data from server
  // const [dataText, setDataText] = React.useState(undefined);
  // const [dataImage, setDataImage] = React.useState(undefined);
  const [dataTweet, setDataTweet] = React.useState(undefined);
  const [tweetLoading, setTweetLoading] = React.useState(false);

  // search form fields
  // const [formText, setFormText] = React.useState("Sample text");
  // const [formPoints, setFormPoints] = React.useState("20");
  // const [formChamber, setFormChamber] = React.useState("House");
  const [formTweet, setFormTweet] = React.useState("Scientists, business owners, even our kids know #ClimateChange is real. Our gov’t shouldn’t run away from it. #ParisAgreement");

  // headers
  const headers = {
    "Content-Type": "application/json",
    "authorization": token,
  }

  // reset scrolling
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // history
  const history = useHistory();

  //--------------------------------------------------
  // API methods
  //--------------------------------------------------

  const analyzeTweet = () => {
    setTweetLoading(true);
    const url = "http://localhost:4100/congressional-tweets-project/analyze-tweet";
    const body = {
      "input": formTweet,
    };
    Axios.post(url, body, { headers: headers })
    .then(response => {
      const data = response.data.output;
      setDataTweet(data);
      setTweetLoading(false);
    })
    .catch(error => {
      console.log(error);
    });
  };

  // const getImage = () => {
  //   const text = formText
  //   // setDataImage(undefined);
  //   setImageLoading(true);
  //   const url = "http://localhost:4100/congressional-tweets-project/make-plot";
  //   const body = {
  //     "points": formPoints,
  //   };
  //   Axios.post(url, body, { headers: headers })
  //   .then(response => {
  //     const data = response.data.output;
  //     setDataImage(data);
  //     setImageLoading(false);
  //   })
  //   .catch(error => {
  //     console.log(error);
  //   });
  // };

  //--------------------------------------------------
  // button methods
  //--------------------------------------------------

  const clickAnalyzeButton = () => {
    // getText();
    // getImage();
    analyzeTweet();
  };

  // const clickResetFormButton = () => {
  //   setFormText("Sample text");
  //   setFormPoints("20");
  // };

  //--------------------------------------------------
  // menu
  //--------------------------------------------------

  const menu =
  <div className="csm-menu">
    <div className="csm-menu-title">
      CSM Scores
    </div>
    <div className="csm-menu-tab-container">
      <div className="csm-menu-tab">
        Chamber
      </div>
      <div className="csm-menu-tab">
        Members
      </div>
      <div className="csm-menu-tab">
        Tweets
      </div>
    </div>
  </div>

  //--------------------------------------------------
  // search form
  //--------------------------------------------------

  const search =
  <div>
    <div className="csm-search-form">
      <div className="csm-row-center">
        {/* <div className="csm-form-container">
          <TextField
            style={{ width: 200 }}
            variant="filled"
            label="Tweet text"
            value={formText}
            helperText=""
            onChange={(event) => setFormText(event.target.value)}
          />
        </div>
        <div className="csm-form-container">
          <TextField
            style={{ width: 200 }}
            variant="filled"
            label="Number of points"
            value={formPoints}
            helperText=""
            onChange={(event) => setFormPoints(event.target.value)}
          />
        </div>
        <div className="csm-row-center">
          <div className="csm-form-container">
            <FormControl fullWidth>
              <InputLabel>Chamber</InputLabel>
              <Select
                style={{ width: 200 }}
                variant="filled"
                value={formChamber}
                IconComponent={SelectArrow}
                onChange={(event) => setFormChamber(event.target.value)}
              >
                <MenuItem value="House">House</MenuItem>
                <MenuItem value="Senate">Senate</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div> */}
        <div className="csm-row-center">
          <div className="csm-form-container">
            <TextField
              style={{ width: 500 }}
              multiline
              minRows={4}
              variant="filled"
              label="Tweet text"
              value={formTweet}
              helperText=""
              onChange={(event) => setFormTweet(event.target.value)}
            />
          </div>
        </div>
        <div className="csm-row-center">
          <div className="csm-form-container">
            <div className="csm-button" onClick={clickAnalyzeButton}>Get started</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  //--------------------------------------------------
  // progress bar
  //--------------------------------------------------

  var progressBar = null;
  if (tweetLoading) {
    progressBar =
    <div className="csm-progress-bar-container">
      <CircularProgress className="csm-progress-bar" disableShrink size={75} thickness={3} />
    </div>
  } else {
    progressBar =
    <div className="csm-progress-bar-container-hidden">
      <CircularProgress className="csm-progress-bar" disableShrink size={75} thickness={3} />
    </div>
  }

  //--------------------------------------------------
  // results
  //--------------------------------------------------

  var results = null;
  // dataText !== undefined && dataImage !== undefined
  if(dataTweet !== undefined) {

    results =
    <div className={tweetLoading ? "csm-results-container-hidden" : "csm-results-container"}>
      <div className="csm-tweet-container">
        <div className="csm-tweet-tweet">
          {dataTweet.tweet_raw}
        </div>
      </div>
      <div className="csm-block">
        <div className="csm-title">
          Analysis
        </div>
        <div className="csm-paragraph">
          The model estimates a single CSM-T score for each tweet. However, there is always some degree of uncertainty in the model's prediction. The plot below shows a distribution that reflects the model's uncertainty about the value of the CSM-T score. (Technically, this distribution is an approximation of the Bayesian posterior distribution for the estimated CSM-T score. See the methodology section for more information.) The estimated CSM-T score is the mean of this distribution.
        </div>
        <div className="csm-paragraph">
          Note that while the model may be uncertain about the CSM-T score for any given tweet, taking the mean of the CSM-T scores for each member produces CSM-M scores that are highly robust to reestimating the model.
        </div>
        <div className="csm-plot-container">
          <iframe src={dataTweet.tweet_plot.url} width={700} height={700 * dataTweet.tweet_plot.aspect_ratio} frameBorder={0} scrolling="no"/>
        </div>
        <div className="csm-paragraph">
          The x-axis is the CSM-T score and the y-axis is the density at each value. The model estimates that this tweet has a <span className="csm-bold">CSM-T score of {dataTweet.score}</span>. This estimate is probabilistic, so the distribution could change slightly if you run it again. The estimated CSM-T score (the median of the distribution) is generally robust from run to run.
        </div>
        <div className="csm-paragraph">
          The closer a tweet's estimated CSM-T score is to the center of the distribution, the less partisan it is. (A tweet can also be in the center of the scale because it does not contain political content, and therefore is equally inconsistent with positions taken by both parties.) A tweet can be relatively non-partisan independent of its ideological content. It could be relatively inconsistent with the position-taking by both parties because it is ideologically moderate or because it is ideologically extreme in a way that is inconsistent with positions taken by both parties.
        </div>
        <div className="csm-paragraph">
          The width of the distribution indicates how uncertain the model is about the CSM-T score. Some tweets will have very wide distributions and some will have very narrow distributions, depending on the language used. Highly partisan tweets will tend to have narrow distributions located near the ends of the scale. Moderately partisan tweets will tend to have distributions that span most or all of the scale, but the distribution will clearly be skewed towards one end or the other.
        </div>
        <div className="csm-paragraph">
          Non-partisan tweets that have political content will have narrow distributions located near the middle of the scale. Tweets that do not have clear political content are more likely to have wide, flat distributions that span most or all of the scale.
        </div>
      </div>
    </div>
  } else {
    results =
    <div className ="csm-results-placeholder"/>;
  }

  //--------------------------------------------------
  // content
  //--------------------------------------------------

  var content =
  <div>
    <div className="csm-block">
      <div className="csm-title">
        Congressional Social Media (CSM) Scores
      </div>
      <div className="csm-paragraph">
        When members of Congress tweet, they take political positions on a wide variety of issues. Congressional Social Media (CSM) scores are a new quantitative estimate of how partisan members of Congress are on Twitter in terms of the positions they take, which are reflected in the language members use in their tweets. A partisan member of Congress is one who consistently articulates positions that are consistent with their party's positions.
      </div>
      <div className="csm-paragraph">
        This app provides a variety of interactive tools that you can use to visualize CSM scores for members of the 115th Congress. You can visualize the distribution of CSM scores for the House of Representatives and the Senate, you can analyze the tweets of specific members of Congress, and you can analyze specific tweets posted by members of Congress or any other tweets that have political content.
      </div>
      <div className="csm-paragraph">
        I estimate CSM scores based on the text of members' tweets. I use machine learning (deep convolutional neural networks) to score all tweets posted by members of the 115h Congress (January 3, 2017 to January 3, 2019). See the methodology section for more information on how CSM scores are estimated. The sample includes approximately 250,000 tweets by senators and approximately 630,000 tweets by representatives. This works out to around 2,500 tweets per senator and around 1,450 tweets per representative.
      </div>
      <div className="csm-paragraph">
        I generate one CSM score per tweet in the sample, called a CSM-T score. I then average these CSM-T scores by member to create member-level scores, called CSM-M scores, that characterize each member's position-taking behavior on Twitter over the course of the entire 115th Congress. I can also average the tweet-level scores by member and by month, called CSM-MM scores, to create a measure of position-taking that varies over time.
      </div>
    </div>
    <div className="csm-block-shaded">
      <div className="csm-title">
        Analyze a tweet
      </div>
      <div className="csm-paragraph">
        You can use this feature to analyze individual tweets. You can estimate a tweet's CSM-T score and see how precise the model's estimate is, how the CSM-T score for the tweet compares with the distribution of CSM-T scores for the member who posted the tweet, and how sensitive the model's estimate of the tweet's CSM-T score is to the inclusion or exclusion of certain words or phrases in the tweet.
      </div>
    </div>
    {search}
    {progressBar}
    {results}
  </div>

  //--------------------------------------------------
  // return
  //--------------------------------------------------

  return (
    <div className="csm-page">
      {menu}
      {content}
      <Footer />
    </div>
  );
};


const databases = {

  cjeuDatabasePlatform: {
    url: "cjeu-database-platform",
    short_name: "CJEU Database Platform",
    long_name: "Court of Justice of the European Union (CJEU) Database Platform: Decisions and Decision-Makers",
    author: ["Stein Arne Brekke", "Joshua C. Fjelstul", "Silje Synnøve Lyder Hermansen", "Daniel Naurin"],
    description: "The Court of Justice of the European Union (CJEU) Database Platform is the largest and most comprehensive research-oriented database on the CJEU. It includes data on CJEU cases, decisions, and judges collected from the official register of the Court, InfoCuria (the Court's official database), and EUR-Lex (the EU's official database of legal documents). All of the data has been cleaned and cross-validated and is research-ready. The database currently includes datasets that cover CJEU cases, the parties (application and defendant) in each case, CJEU decisions (judgments, orders, opinions, etc.), the assignment of judges to the chamber that issued each decision, the legal procedures associated with in decision (references for a preliminary ruling, actions for annulment, appeals, etc.), submissions of observations and interventions with respect to each decision, citations to EU legal documents in each decision, and CJEU judges and Advocates General.",
    class: "personal-block-white",
    button_label: "Learn more",
    button_link: "https://iuropa.pol.gu.se/CJEU-database",
  },

  cjeuTextCorpus: {
    url: "cjeu-text-corpus",
    short_name: "CJEU Document Database",
    long_name: "Court of Justice of the European Union (CJEU) Document Database",
    author: ["Joshua C. Fjelstul", "Johan Lindholm", "Daniel Naurin", "Michal Ovadek"],
    description: "The Court of Justice of the European Union (CJEU) Document Corpus contains the full text of all judgments, orders, and Advocate General opinions for the Court of Justice, the General Court, and the Civil Service Tribunal from 1952 through 2021. The text is available in French and (when an official translation exists) in English. Each document is divided into paragraphs. The operative part of each document (if applicable) is tagged. The database is updated regularly.",
    class: "personal-block-shaded",
    button_label: "Learn more",
    button_link: "https://iuropa.pol.gu.se/CJEU-database",
  },

  evoeu: {
    url: "evoeu",
    codeName: "evoeu",
    short_name: "EvoEU Database",
    long_name: "Evolution of European Union Law (EvoEU) Database",
    author: ["Joshua C. Fjelstul"],
    description: "The Evolution of European Union Law (EvoEU) Database contains network data on the near-universe of legal documents in the acquis communautaire of the European Union (EU), including primary law, secondary law, and case law, and various types of links between documents (1951-2015). The database includes a nodes dataset and a links dataset.",
    class: "personal-block-white",
    button_label: "View on GitHub",
    button_link: "https://github.com/jfjelstul/evoeu",
  },

  ecio: {
    url: "ecio",
    codeName: "ecio",
    short_name: "ECIO Database",
    long_name: "Eurpoean Commission Internal Organization (ECIO) Database",
    author: ["Joshua C. Fjelstul"],
    description: "The European Commission Internal Organization (ECIO) Database contains data on each commission of the European Commission, including data on commissioners, departments (Directorates-General and service departments), and commissioner portfolios (1958-2021). The database includes 11 datasets.",
    class: "personal-block-shaded",
    button_label: "View on GitHub",
    button_link: "https://github.com/jfjelstul/ecio",
  },

  euip: {
    url: "euip",
    codeName: "euip",
    short_name: "EUIP Database",
    long_name: "European Union Infringement Procedure (EUIP) Database",
    author: ["Joshua C. Fjelstul"],
    description: "The European Union Infringement Procedure (EUIP) Database contains data on the European Commission infringement procedure, including data on infringement cases and decisions in infringement cases (2002-2020). The EUIP Database is part of the European Union Compliance Project (EUCP). The database includes 22 datasets, including time-series, cross-sectional time-series, directed dyad-year, and network data.",
    class: "personal-block-white",
    button_label: "View on GitHub",
    button_link: "https://github.com/jfjelstul/euip",
  },

  eusa: {
    url: "eusa",
    codeName: "eusa",
    short_name: "EUSA Database",
    long_name: "European Union State Aid (EUSA) Database",
    author: ["Joshua C. Fjelstul"],
    description: "The European Union State Aid (EUSA) Database contains data on state aid in the European Union (EU), including data on European Commission state aid cases (2000-2020), decisions in state aid cases (2000-2020), and state aid awards granted by member states (2016-2020). The EUSA Database is part of the European Union Compliance Project (EUCP). The database includes 27 datasets, including time-series, cross-sectional time-series, directed dyad-year, and network data.",
    class: "personal-block-shaded",
    button_label: "View on GitHub",
    button_link: "https://github.com/jfjelstul/eusa",
  },

  eutr: {
    url: "eutr",
    codeName: "eutr",
    short_name: "EUTR Database",
    long_name: "European Union Technical Regulations (EUTR) Database",
    author: ["Joshua C. Fjelstul"],
    description: "The European Union Technical Regulations (EUTR) Database contains data on technical regulations proposed by European Union (EU) member states, including data on notifications of proposed technical regulations and comments and detailed opinions submitted by third-party member states and institutions under the 2015/1535 procedure (1986-2020). The EUTR Database is part of the European Union Compliance Project (EUCP). The database includes 15 datasets, including time-series, cross-sectional time-series, directed dyad-year, and network data.",
    class: "personal-block-white",
    button_label: "View on GitHub",
    button_link: "https://github.com/jfjelstul/eutr",
  },

  eums: {
    url: "eums",
    codeName: "eums",
    short_name: "EUMS Database",
    long_name: "European Union Member States (EUMS) Database",
    author: ["Joshua C. Fjelstul"],
    description: "The European Union Member State (EUMS) Database contains general data on European Union (EU) member states, including data on accession, domestic political systems, membership in the European Economic and Monetary Union (EMU) and Schengen Area, legal obligations and opt-outs, and membership in other international organizations. The database includes 4 datasets.",
    class: "personal-block-shaded",
    button_label: "View on GitHub",
    button_link: "https://github.com/jfjelstul/eums",
  },

  ungaVotingRecords: {
    url: "ungadm",
    short_name: "UNGA-DM Database",
    long_name: "United Nations General Assmebly Decision-Making (UNGA-DM) Database",
    author: ["Joshua C. Fjelstul", "Simon Hug", "Christopher Kilby"],
    description: "The United Nations General Assembly Voting Records Database includes comprehensive data on all resolution-related decisions in the General Assembly since 1946. It includes complete data on all resolution-related recorded votes (i.e., roll-call) and decisions, including final passage votes, votes on proposed amendments to draft resolutions, votes on motions, and votes on individual paragraphs of draft resolutions. It includes all successful votes and failed votes. It also includes comprehensive data on all adopted UNGA resolutions, including whether the resolution was adopted without a vote, with a non-recorded vote, or with a recorded vote.",
    class: "personal-block-white",
    button_label: "Contact me",
    button_link: "mailto: joshua.fjelstul@unige.ch",
  },

  unia: {
    url: "unia",
    short_name: "UNIA Database",
    long_name: "United Nations Institutional Activity (UNIA) Database",
    author: ["Joshua C. Fjelstul"],
    description: "The United Nations Institutional Activity (EUIA) Database is a collection of 13 datasets on the United Nations Generaly Assembly (UNGA) and 25 datasets on the United Nations Security Council (UNSC). The database includes data on all UNGA and UNSC resolutions since 1946 and the full text of the official meeting records since 1994, which are parsed by line and paragraph. It also includes extensive data on all decisions, recorded votes (i.e., roll-call votes), speeches, and citations in the meeting records since 1994. The UNSC data includes the full text of presidential statements and draft resolutions.",
    class: "personal-block-shaded",
    button_label: "Contact me",
    button_link: "mailto: joshua.fjelstul@unige.ch",
  },

  cpp: {
    url: "cpp",
    short_name: "CPP Database",
    long_name: "Canadian Parliament Project (CPP) Database",
    author: ["Joshua C. Fjelstul"],
    description: "The Canadian Parliament Project (CPP) Database is the largest and most comprehensive research-oriented database about Canadian politics. The CPP Database includes 41 datasets that cover nearly all aspects of parliamentary politics in the House of Commons. The database maps together members, bills, divisions (i.e., roll-call votes) speeches, motions, written questions, oral questions, and more to provide a near-complete picture of legislative activity. All together, these datasets contain over 8 million observations. The database also includes hundreds of millions of words of political text, including the entire corpus of floor speeches and committee speeches from the 1st Session of the 39th Parliament (2006) through the 1st Session of the 43rd Parliament (2020).",
    class: "personal-block-white",
    button_label: "Contact me",
    button_link: "mailto: joshua.fjelstul@unige.ch",
  },

  worldCup: {
    url: "world-cup-database",
    short_name: "World Cup Database",
    long_name: "Fjelstul World Cup Database",
    author: ["Joshua C. Fjelstul"],
    description: "The Fjelstul World Cup Database is a comprehensive database about the FIFA World Cup created by Joshua C. Fjelstul, Ph.D. that covers all 21 World Cup tournaments (1930-2018). The database includes 27 datasets (approximately 1.1 million data points) that cover all aspects of the World Cup. The data has been extensively cleaned and cross-validated.",
    class: "personal-block-white",
    button_label: "View on GitHub",
    button_link: "https://github.com/jfjelstul/worldcup",
  }
}

const datasets = {

  evoeu: [
    { name: "nodes", label: "Data on documents in the acquis communautaire", description: "This dataset includes one one observation per node in the network of European Union legal documents. Each node is a document. This network includes primary law (i.e., treaty articles), secondary law (i.e., legislation), and case law (i.e., court cases). The dataset indicates the CELEX number of each document, which is a unique ID number assigned by EUR-Lex, the date of the document, the author of the document, and the type of the document. The dataset includes the following types of documents: treaty articles, directives, regulations, decisions, Court of Justice judgments, advocate general opinions, General Court judgments, national implementing measures (i.e., national legislation that member states enact to transpose EU directives), and national court decisions that apply EU law." },
    { name: "edges", label: "Data on links between documents in the acquis communautaire ", description: "This dataset includes on observation per edges in the network of European Union legal documents. An edge is a connection between two documents, created by the newer of the two documents. The dataset tracks the following types of connections: cites all or part of (most types of documents), changes text in clause of (secondary laws), replaces clause of (secondary laws), adds text to clause of (secondary laws), inserts new clause in (secondary laws), repeals all or part of (secondary laws), has legal basis in (secondary laws), interprets all or part of (references for a preliminary ruling), answers question referred by (references for a preliminary ruling), determines compliance with (actions for failure to fulfill obligations), upholds all or part of (case law), overturns all or part of (case law), and transposes all or part of (national implementing measures)." },
  ],

  ecio: [
    { name: "commissions", label: "Data on commissions", description: "This dataset includes data on Commissions of the European Union (1958-2021). There is one observation per Commission. The data indicates the national party and European political group of the President of the Commission and the start and end dates of each Commission. The Mansholt Commission is treated as its own Commission. The Marín Commission is treated as part of the Santer Commission." },
    { name: "departments", label: "Data on departments", description: "This dataset includes data on departments of the Commission, including Directorates-General and service departments (1958-2021). There is one observation per unique department. A new observation is created every time a department is renamed, even if the department itself does not change. This allows you to track changes in department nomenclature over time." },
    { name: "departments_by_commission", label: "Data on departments by commission", description: "This dataset includes data on departments of the Commission, including Directorates-General and service departments (1958-2021). There is one observation per department per Commission. This dataset provides a snapshot of each Commission at the time that the first directory is published. For Commissions that come into office early in a year, the first directory is usually published later that year. For Commissions that come into office late in a year, the first directory is usually published early the next year. The exception is the Prodi Commission. The data for this Commission comes from the earliest available directory." },
    { name: "commissioners", label: "Data on commissioners", description: "This dataset includes data on Commissioners (1958-2021). There is one observation per individual who has served as a Commissioner. The dataset indicates the national party and European political group of each individual, the Commissions that the individual served during, and the earliest and latest dates that each individual was in office." },
    { name: "commissioners_by_commission", label: "Data on commissioners by commission", description: "This dataset includes data on Commissioners (1958-2021). There is one observation per individual who has served as a Commissioner per Commission. The dataset indicates the national party and European political group of each individual, the start and end dates for each individual (within each Commission), information on whether the individual served for the entire Commission, was appointed during the Commission, or left office before the end of the Commission, and whether the portfolio of the individual changed while the individual was in office." },
    { name: "policy_areas", label: "Data on policy areas", description: "This dataset includes data on policy areas covered by departments of the Commission (1958-2021). There is one observation per policy area. EUR-Lex, the Commission's official database of EU legal documents, sorts documents based on a case law directory and a directory of legal acts. There is a pre-Lisbon and post-Lisbon version of the case law directory. The dataset indicates the directory codes that correspond to each policy area according to each of these classification schemes." },
    { name: "portfolio_allocations", label: "Data on portfolio allocations", description: "This dataset includes data on portfolio allocations (1958-2021). There is one observation per portfolio allocation per Commission (i.e., one observation per portfolio per Commissioner per Commission). A new observation is created every time the portfolio of a Commissioner changes, regardless of whether that change results in a change in the departments that the Commissioner oversees. There can be multiple observations per individual per Commission if an individual's portfolio changes during the Commission. Portfolio allocations do not span Commissions. If a Commissioner has the same portfolio for more than one Commission, there will be multiple observations, one for each Commission. The dataset indicates the start and end dates of each portfolio allocation, the departments that each Commissioner oversees, and the policy areas that correspond to those departments." },
    { name: "department_allocations", label: "Data on department allocations", description: "This dataset includes data on department allocations (1958-2021). There is one observation per department allocation per Commission (i.e., one observation per department per Commissioner per Commission). A new observation is created every time a change in the portfolio of a Commissioner leads to a change in who oversees a department. There can be multiple observations per individual per Commission if an individual oversees multiple departments or if the individual's portfolio changes during the Commission. Department allocations do not span Commissions. If a Commissioner oversees the same department for more than one Commission, there will be multiple observations, one for each Commission. The dataset indicates the start and end dates of each department allocation and the policy areas that correspond to each department." },
    { name: "policy_area_allocations", label: "Data on policy area allocations", description: "This dataset includes data on policy area allocations (1958-2021). There is one observation per policy area allocation per Commission (i.e., one observation per policy area per Commissioner per Commission). A new observation is created every time a change in the portfolio of a Commissioner leads to a change in who oversees a policy area. There can be multiple observations per individual per Commission if an individual oversees multiple policy areas or if the individual's portfolio changes during the Commission. Policy area allocations do not span Commissions. If a Commissioner oversees a policy area for more than one Commission, there will be multiple observations, one for each Commission. The dataset indicates the start and end dates of each policy allocation." },
    { name: "department_histories", label: "Data on department histories", description: "This dataset includes data on the histories of the current departments of the Commission, including Directorates-General and service departments (1958-2021). There is one observation per historical interval in which the nomenclature of a department is constant. Unlike allocations, intervals can span Commissions. By sorting the dataset by current department, you can see the evolution of each department over time. The dataset indicates the Commissions associated with each interval and the start and end dates of each interval." },
    { name: "policy_area_histories", label: "Data on policy area histories", description: "This dataset includes data on the histories of the policy areas covered by the departments of the Commission (1958-2021). There is one observation per historical interval in which the mapping between a policy area and a department is constant. If the nomenclature of a department changes, a new interval will be created. Unlike allocations, intervals can span Commissions. There can also be multiple overlaping intervals per policy area when a policy area is split across multiple departments at the same time. By sorting the dataset by policy area, you can see the evolution of the mapping between the policy area and departments over time. The dataset indicates the Commissions associated with each interval and the start and end dates of each interval." },
  ],

  euip: [
    { name: "cases", label: "Case-level data", description: "This dataset include data on infringement cases. There is one observation per case  (2002-2020). The dataset includes information on the department responsible for the case, the member state that the case is against, and the progression of the case through the infringement procedure." },
    { name: "cases_ts", label: "Case-level time-series data", description: "This dataset includes aggregated data on the number of infringement cases per year (time-series data). There is one observation per year (2002-2020)." },
    { name: "cases_ts_ct", label: "Case-level time-series data by case type", description: "This dataset includes aggregated data on the number of infringement cases per year (time-series data) broken down by case type (noncommunication vs nonconformity). There is one observation per year per case type (2002-2020)." },
    { name: "cases_csts_ms", label: "Case-level cross-sectional time-series data by member state", description: "This dataset includes aggregated data on the number of infringement cases per member state per year (cross-sectional time-series data). There is one observation per member state per year (2002-2020), excluding state-years where the state was not a member of the EU." },
    { name: "cases_csts_ms_ct", label: "Case-level cross-sectional time-series data by member state and case type", description: "This dataset includes aggregated data on the number of infringement cases per member state per year (cross-sectional time-series data) broken down by case type (noncommunication vs nonconformity). There is one observation per member state per year per case type (2002-2020), excluding state-years where the state was not a member of the EU." },
    { name: "cases_csts_dp", label: "Case-level cross-sectional time-series data by department", description: "This dataset includes aggregated data on the number of infringement cases per department per year (cross-sectional time-series data). There is one observation per department per year (2002-2020)." },
    { name: "cases_csts_dp_ct", label: "Case-level cross-sectional time-series data by department and case type", description: "This dataset includes aggregated data on the number of infringement cases per department per year (cross-sectional time-series data) broken down by case type (noncommunication vs nonconformity). There is one observation per department per year per case type (2002-2020). The dataset uses current department names." },
    { name: "cases_ddy", label: "Case-level directed dyad-year data", description: "This dataset includes aggregated data on the number of infringement cases per department per member state per year (directed dyad-year data). There is one observation per department per member state per year (2002-2020), excluding directed dyad-years where the state was not a member of the EU. The dataset uses current department names." },
    { name: "cases_ddy_ct", label: "Case-level directed dyad-year data by case type", description: "This dataset includes aggregated data on the number of infringement cases per department per member state per year (directed dyad-year data) broken down by case type (noncommunication vs nonconformity). There is one observation per department per member state per year per case type (2002-2020), excluding directed dyad-years where the state was not a member of the EU. The dataset uses current department names." },
    { name: "cases_net", label: "Case-level network data", description: "This dataset includes network data on infringement cases. Network data is similar to directed dyad-year data except that it only includes directed dyad-years with at least one infringement case. For every year, there is one node per department and one node per member state. Edges can only exist between a department and a member state. There is an edge between a department and a member state if and only if the department opened at least one case against the member state during that year. The weight of the edge is the number of cases that the department opened against the member state. There is one observation per department per member state per year (2002-2020), excluding directed dyad-years where the state was not a member of the EU, but only if count of cases is positive." },
    { name: "cases_net_ct", label: "Case-level network data by case type", description: "This dataset includes multi-dimensional network data on infringement cases. There is one dimension per case type (noncommunication vs nonconformity). Network data is similar to directed dyad-year data except that it only includes directed dyad-years with at least one infringement case with respect to each case type. For every year, there is one node per department and one node per member state. Edges can only exist between a department and a member state. There is an edge between a department and a member state with respect to each case type if and only if the department opened at least one case against the member state during that year. The weight of the edge is the number of cases that the department opened against the member state. There is one observation per department per member state per year per case type (2002-2020), excluding directed dyad-years where the state was not a member of the EU, but only if the count of cases is positive." },

    { name: "decisions", label: "Decision-level data", description: "This dataset include data on infringement decisions. There is one observation per decision per case (2002-2020). The dataset includes information on the department responsible for the decision and the member state that the decision is against." },
    { name: "decisions_ts", label: "Decision-level time-series data", description: "This dataset includes aggregated data on the number of decisions per stage of the infringement procedure per year (time-series data). There is one observation per year per decision stage (2002-2020)." },
    { name: "decisions_ts_ct", label: "Decision-level time-series data by case type", description: "This dataset includes aggregated data on the number of decisions per stage of the infringement procedure per year (time-series data) broken down by case type (nonommunication vs nonconformity). There is one observation per year per decision stage per case type (2002-2020)." },
    { name: "decisions_csts_ms", label: "Decision-level cross-sectional time-series data by member state", description: "This dataset includes aggregated data on the number of decisions per stage of the infringement procedure per member state per year (cross-sectional time-series data). There is one observation per member state per year per decision stage (2002-2020), excluding state-years where the state was not a member of the EU." },
    { name: "decisions_csts_ms_ct", label: "Decision-level cross-sectional time-series data by member state and case type", description: "This dataset includes aggregated data on the number of decisions per stage of the infringement procedure per member state per year (cross-sectional time-series data) broken down by case type (noncommunication vs nonconformity). There is one observation per member state per year per decision stage per case type (2002-2020), excluding state-years where the state was not a member of the EU." },
    { name: "decisions_csts_dp", label: "Decision-level cross-sectional time-series data by department", description: "This dataset includes aggregated data on the number of decisions per stage of the infringement procedure per department per year (cross-sectional time-series data). There is one observation per department per year per decision stage (2002-2020). The dataset uses current department names." },
    { name: "decisions_csts_dp_ct", label: "Decision-level cross-sectional time-series data by department and case type", description: "This dataset includes aggregated data on the number of decisions per stage of the infringement procedure per department per year (cross-sectional time-series data) broken down by case type (noncommunication vs nonconformity). There is one observation per department per year per decision stage per case type (2002-2020). The dataset uses current department names." },
    { name: "decisions_ddy", label: "Decision-level directed dyad-year data", description: "This dataset includes aggregated data on the number of decisions per stage of the infringement procedure per department per member state per year (directed dyad-year data). There is one observation per department per member state per year per decision stage (2002-2020), excluding directed dyad-years where the state was not a member of the EU. The dataset uses current department names." },
    { name: "decisions_ddy_ct", label: "Decision-level directed dyad-year data by case type", description: "This dataset includes aggregated data on the number of decisions per stage of the infringement procedure per department per member state per year (directed dyad-year data) broken down by case type (noncommunication vs nonconformity). There is one observation per department per member state per year per case type per decision stage (2002-2020), excluding directed dyad-years where the state was not a member of the EU. The dataset uses current department names." },
    { name: "decisions_net", label: "Decision-level network data", description: "This dataset includes multi-dimensional network data on infringement decisions. There is one dimension per decision stage. Network data is similar to directed dyad-year data except that it only includes directed dyad-years with at least one decision with respect to each stage. For every year, there is one node per department and one node per member state. Edges can only exist between a department and a member state. There is an edge between a department and a member state with respect to each stage if and only if the department issued at least one decision against the member state during that year. The weight of the edge is the number of decisions that the department opened against the member state. There is one observation per department per member state per year per decision stage (2002-2020), excluding directed dyad-years where the state was not a member of the EU, but only if count of decisions is positive." },
    { name: "decisions_net_ct", label: "Decision-level network data by case type", description: "This dataset includes multi-dimensional network data on infringement decisions. There is one dimension per case type (noncommunication vs nonconformity) per decision stage. Network data is similar to directed dyad-year data except that it only includes directed dyad-years with at least one decision with respect to each case type and stage. For every year, there is one node per department and one node per member state. Edges can only exist between a department and a member state. There is an edge between a department and a member state with respect to each case type and stage if and only if the department issued at least one decision against the member state during that year. The weight of the edge is the number of decisions that the department opened against the member state. There is one observation per department per member state per year per case type per decision stage (2002-2020), excluding directed dyad-years where the state was not a member of the EU, but only if the count of decisions is positive." },
  ],

  eusa: [
    { name: "cases", label: "Case-level data", description: "This dataset include data on state aid cases. There is one observation per case (1988-2020). The dataset includes information on the department responsible for the case, the member state that the case is against, and decisions taken by the Commission in the case." },
    { name: "cases_ts", label: "Case-level time-series data", description: "This dataset includes aggregated data on the number of state aid cases per year (time-series data). There is one observation per year (1988-2020)." },
    { name: "cases_ts_ct", label: "Case-level time-series data by case type", description: "This dataset includes aggregated data on the number of state aid cases per year (time-series data) broken down by case type. There is one observation per year per case type (1988-2020)." },
    { name: "cases_csts_ms", label: "Case-level cross-sectional time-series data by member state", description: "This dataset includes aggregated data on the number of state aid cases per member state per year (cross-sectional time-series data). There is one observation per member state per year (1988-2020), excluding state-years where the state was not a member of the EU." },
    { name: "cases_csts_ms_ct", label: "Case-level cross-sectional time-series data by member state and case type", description: "This dataset includes aggregated data on the number of state aid cases per member state per year (cross-sectional time-series data) broken down by case type. There is one observation per member state per year per case type (1988-2020), excluding state-years where the state was not a member of the EU." },
    { name: "cases_csts_dp", label: "Case-level cross-sectional time-series data by department", description: "This dataset includes aggregated data on the number of state aid cases per department per year (cross-sectional time-series data). There is one observation per department per year (1988-2020). The dataset uses current department names." },
    { name: "cases_csts_dp_ct", label: "Case-level cross-sectional time-series data by department and case type", description: "This dataset includes aggregated data on the number of state aid cases per department per year (cross-sectional time-series data) broken down by case type. There is one observation per department per year per case type (1988-2020). The dataset uses current department names." },
    { name: "cases_ddy", label: "Case-level directed dyad-year data", description: "This dataset includes aggregated data on the number of state aid cases per department per member state per year (directed dyad-year data). There is one observation per department per member state per year (1988-2020), excluding directed dyad-years where the state was not a member of the EU. The dataset uses current department names." },
    { name: "cases_ddy_ct", label: "Case-level directed dyad-year data by case type", description: "This dataset includes aggregated data on the number of state aid cases per department per member state per year (directed dyad-year data) broken down by case type. There is one observation per department per member state per year per case type (1988-2020), excluding directed dyad-years where the state was not a member of the EU. The dataset uses current department names." },
    { name: "cases_net", label: "Case-level network data", description: "This dataset includes network data for state aid cases. Network data is similar to directed dyad-year data except that it only includes directed dyad-years with at least one infringement case. For every year, there is one node per department and one node per member state. Edges can only exist between a department and a member state. There is an edge between a department and a member state if and only if the department opened at least one case against the member state during that year. The weight of the edge is the number of cases that the department opened against the member state. There is one observation per department per member state per year (1988-2020), excluding directed dyad-years where the state was not a member of the EU, but only if count of cases is positive." },
    { name: "cases_net_ct", label: "Case-level network data by case type", description: "This dataset includes multi-dimensional network data for state aid cases. There is one dimension per case type. Network data is similar to directed dyad-year data except that it only includes directed dyad-years with at least one infringement case with respect to each case type. For every year, there is one node per department and one node per member state. Edges can only exist between a department and a member state. There is an edge between a department and a member state with respect to each case type if and only if the department opened at least one case against the member state during that year. The weight of the edge is the number of cases that the department opened against the member state. There is one observation per department per member state per year per case type (1988-2020), excluding directed dyad-years where the state was not a member of the EU, but only if the count of cases is positive." },

    { name: "decisions", label: "Decision-level data", description: "This dataset include data on decisions in state aid cases. There is one observation per decision per case (1988-2020). The dataset includes information on the department responsible for the decision and the member state that the decision is against." },
    { name: "decisions_ts", label: "Decision-level time-series data", description: "This dataset includes aggregated data on the number of each type of decision per year (time-series data). There is one observation per year per decision stage (1988-2020)." },
    { name: "decisions_ts_ct", label: "Decision-level time-series data by case type", description: "This dataset includes aggregated data on the number of each type of decision per year (time-series data) broken down by case type. There is one observation per year per decision type per case type (1988-2020)." },
    { name: "decisions_csts_ms", label: "Decision-level cross-sectional time-series data by member state", description: "This dataset includes aggregated data on the number of each type of decision per member state per year (cross-sectional time-series data). There is one observation per member state per year per decision type (1988-2020), excluding state-years where the state was not a member of the EU." },
    { name: "decisions_csts_ms_ct", label: "Decision-level cross-sectional time-series data by member state and case type", description: "This dataset includes aggregated data on the number of each type of decision per member state per year (cross-sectional time-series data) broken down by case type (noncommunication vs nonconformity). There is one observation per member state per year per decision type per case type (1988-2020), excluding state-years where the state was not a member of the EU." },
    { name: "decisions_csts_dp", label: "Decision-level cross-sectional time-series data by department", description: "This dataset includes aggregated data on the number of each type of decision per department per year (cross-sectional time-series data). There is one observation per department per year per decision type (1988-2020). The dataset uses current department names." },
    { name: "decisions_csts_dp_ct", label: "Decision-level cross-sectional time-series data by department and case type", description: "This dataset includes aggregated data on the number of each type of decision per department per year (cross-sectional time-series data) broken down by case type (noncommunication vs nonconformity). There is one observation per department per year per decision type per case type (1988-2020). The dataset uses current department names." },
    { name: "decisions_ddy", label: "Decision-level directed dyad-year data", description: "This dataset includes aggregated data on the number of each type of decision per department per member state per year (directed dyad-year data). There is one observation per department per member state per year per decision type (1988-2020), excluding directed dyad-years where the state was not a member of the EU. The dataset uses current department names." },
    { name: "decisions_ddy_ct", label: "Decision-level directed dyad-year data by case type", description: "This dataset includes aggregated data on the number of each type of decision per department per member state per year (directed dyad-year data) broken down by case type (noncommunication vs nonconformity). There is one observation per department per member state per year per case type per decision type (1988-2020), excluding directed dyad-years where the state was not a member of the EU. The dataset uses current department names." },
    { name: "decisions_net", label: "Decision-level network data", description: "This dataset includes multi-dimensional network data for decisions in state aid cases. There is one dimension per decision type. Network data is similar to directed dyad-year data except that it only includes directed dyad-years with at least one decision. For every year, there is one node per department and one node per member state. Edges can only exist between a department and a member state. There is an edge between a department and a member state with respect to each decision type if and only if the department issued at least one decision against the member state during that year. The weight of the edge is the number of decisions that the department opened against the member state. There is one observation per department per member state per year per decision type (1988-2020), excluding directed dyad-years where the state was not a member of the EU, but only if count of decisions is positive." },
    { name: "decisions_net_ct", label: "Decision-level network data by case type", description: "This dataset includes multi-dimensional network data for infringement decisions. There is one dimension per case type and per decision type. Network data is similar to directed dyad-year data except that it only includes directed dyad-years with at least one decision. For every year, there is one node per department and one node per member state. Edges can only exist between a department and a member state. There is an edge between a department and a member state with respect to each case type and decision type if and only if the department issued at least one decision against the member state during that year. The weight of the edge is the number of decisions that the department opened against the member state. There is one observation per department per member state per year per case type per decision type (1988-2020), excluding directed dyad-years where the state was not a member of the EU, but only if the count of decisions is positive." },

    { name: "awards", label: "Award-level data", description: "This dataset includes data on state aid awards granted to firms by government agencies in member states that were reported to the Commission. There is one observation per award (2016-2020). The dataset includes information on the date of the award, the aid-granting agency, the beneficiary, the NACE sector of the beneficiary, the aid instrument used, and the estimated amount of the aid in euros." },
    { name: "awards_csts", label: "Award-level cross-sectional time-series data", description: "This dataset includes aggregated data on the number of state aid awards per member state per year (cross-sectional time-series data). There is one observation per member state per year (2016-2020)." },
    { name: "awards_csts_bt", label: "Award-level cross-sectional time-series data by beneficiary type", description: "This dataset includes aggregated data on the number of state aid awards per member state per year (cross-sectional time-series data) broken down by beneficiary type. There is one observation per member state per year per beneficiary type (2016-2020)." },
    { name: "awards_csts_ai", label: "Award-level cross-sectional time-series data by aid instrument", description: "This dataset includes aggregated data on the number of state aid awards per member state per year (cross-sectional time-series data) broken down by aid instrument. There is one observation per member state per year per aid instrument (2016-2020)." },
    { name: "awards_csts_ns", label: "Award-level cross-sectional time-series data by NACE sector", description: "This dataset includes aggregated data on the number of state aid awards per member state per year (cross-sectional time-series data) broken down by NACE sector. There is one observation per member state per year per NACE sector (2016-2020)." },
  ],

  eutr: [
    { name: "notifications", label: "Notification-level data", description: "This dataset include data on technical regulations proposed at the national level by member states of the EU Single Market (including members of the Single Market that are not members of the EU) that are notified to the Commission under the 2015/1535 procedure. There is one observation per notified technical regulation (1988-2020). The dataset includes information on the start and end dates of the case, the member state that notified the proposed technical regulation, which third-party member states or institutions (if any) submitted comments and/or detailed opinions, and whether the Commission submitted a comment and/or detailed opinion." },
    { name: "notifications_ts", label: "Notification-level time-series data", description: "This dataset includes aggregated data on the number of notified technical regulations per year (time-series data). There is one observation per year (1988-2020)." },
    { name: "notifications_csts", label: "Notification-level cross-sectional time-series data", description: "This dataset includes aggregated data on the number of notified technical regulations per member state per year (cross-sectional time-series data). There is one observation per notifying member state per year (2002-2020), excluding state-years where the state was not a member of the Single Market." },

    { name: "comments", label: "Comment-level data", description: "This dataset includes data on comments submitted by third-party member states and institutions in reponse to proposed technical regulations notified by member states (1988-2020). There is one observation per comment. The dataset indicates the the member state or institution that submitted the comment and the member state that notified the proposed technical regulation to the Commission." },
    { name: "comments_ts", label: "Comment-level time-series data", description: "This dataset includes aggregated data on the total number of comments submitted per year (time-series data). There is one observation per year (2002-2020)." },
    { name: "comments_csts_n", label: "Comment-level cross-sectional time-series data by notifier", description: "This dataset includes aggregated data on the number of comments submitted against each notifying member state per year (cross-sectional time-series data). There is one observation per notifying member state per year (1988-2020), excluding state-years where the state was not a member of the Single Market." },
    { name: "comments_csts_s", label: "Comment-level cross-sectional time-series data by submitter", description: "This dataset includes aggregated data on the number of comments submitted by each third-party member state or institution per year (cross-sectional time-series data). There is one observation per third-party member state or institution per year (1988-2020), excluding state-years where the state was not a member of the Single Market." },
    { name: "comments_ddy", label: "Comment-level directed dyad-year data", description: "This dataset includes aggregated data on the number of comments submitted by each third-party member state or institution against each notifying member state per year (directed dyad-year data). There is one observation per third-party member state or institution per notifying member state per year (2002-2020), excluding directed dyad-years where a state was not a member of the Single Market." },
    { name: "comments_net", label: "Comment-level network data", description: "This dataset includes network data on comments. Network data is similar to directed dyad-year data except that it only includes directed dyad-years with at least one comment. For every year, there is one node per member state or institution that can submit a comment or notify a proposed technical regulation (only member states). Links can only exist between a third-party member state or institution that can submit a comment and a member state that can notify a technical regulation. There is a link between a third-party member state or institution and a notifying member state if and only if the former submitted at least one comment with respect to a technical regulation notified by the latter. The weight of the edge is the number of comments submitted. There is one observation per third-party member state or institution per notifying member state per year (2002-2020), excluding directed dyad-years where a state was not a member of the Single Market, but only if count of comments is positive." },

    { name: "opinions", label: "Opinion-level data", description: "This dataset includes data on detailed opinions submitted by third-party member states and institutions in reponse to proposed technical regulations notified by member states (1988-2020). There is one observation per detailed opinion. The dataset indicates the the member state or institution that submitted the detailed opinion and the member state that notified the proposed technical regulation to the Commission." },
    { name: "opinions_ts", label: "Opinion-level time-series data", description: "This dataset includes aggregated data on the total number of detailed opinions submitted per year (time-series data). There is one observation per year (2002-2020)." },
    { name: "opinions_csts_n", label: "Opinion-level cross-sectional time-series data by notifier", description: "This dataset includes aggregated data on the number of detailed opinions submitted against each notifying member state per year (cross-sectional time-series data). There is one observation per notifying member state per year (1988-2020), excluding state-years where the state was not a member of the Single Market." },
    { name: "opinions_csts_s", label: "Opinion-level cross-sectional time-series data by submitter", description: "This dataset includes aggregated data on the number of detailed opinions submitted by each third-party member state or institution per year (cross-sectional time-series data). There is one observation per third-party member state or institution per year (1988-2020), excluding state-years where the state was not a member of the Single Market." },
    { name: "opinions_ddy", label: "Opinion-level directed dyad-year data", description: "This dataset includes aggregated data on the number of detailed opinions submitted by each third-party member state or institution against each notifying member state per year (directed dyad-year data). There is one observation per third-party member state or institution per notifying member state per year (2002-2020), excluding directed dyad-years where a state was not a member of the Single Market." },
    { name: "opinions_net", label: "Opinion-level network data", description: "This dataset includes network data on detailed opinions. Network data is similar to directed dyad-year data except that it only includes directed dyad-years with at least one detailed opinion. For every year, there is one node per member state or institution that can submit a detailed opinion or notify a proposed technical regulation (only member states). Links can only exist between a third-party member state or institution that can submit a detailed opinion and a member state that can notify a technical regulation. There is a link between a third-party member state or institution and a notifying member state if and only if the former submitted at least one detailed opinion with respect to a technical regulation notified by the latter. The weight of the edge is the number of detailed opinions submitted. There is one observation per third-party member state or institution per notifying member state per year (2002-2020), excluding directed dyad-years where a state was not a member of the Single Market, but only if count of comments is positive." },
  ],

  eums: [
    { name: "member_states", label: "Data on member states", description: "This dataset includes data on European Union (EU) member states. There is one observation per member state. The dataset includes information about accession, the political system of the member state, participation in the European Economic and Monetary Union (EMU) and the Schengen Area, legal obligations and opt-outs, and membership in other international organizations." },
    { name: "member_states_csts", label: "Cross-sectional time-series data on member states", description: "This dataset is a template for cross-sectional time-series data on member states. There is one observation per member state per year." },
    { name: "member_states_ddy", label: "Directed dyad-year data on member states", description: "This dataset is a template for directed dyad-year data on member states. There is one observation per directed dyad per year." },
    { name: "qmv_weights", label: "Data on qualified majority voting (QMV) weights", description: "This dataset includes data on qualified majority voting (QMV) weights in the Council of the European Union. The total number of votes allocated across member states and the number of votes allocated to each member state have changed over time. This dataset tracks those changes and calculates the normalized voting weight for each member state for each period." },
  ],

  cjeuDatabasePlatform: [
    { name: "cases", label: "CJEU cases", description: "This dataset includes information on the universe of CJEU cases. There is one observation per case (before joins). The dataset indicates the title of the case, how cases are joined together into proceedings, the date of the hearing (if applicable), the dates and ECLI numbers of documents in the case (judgments, orders, AG opinions), the EUR-Lex subject codes for the case, and more." },
    { name: "parties", label: "Applicants and defendants in CJEU cases", description: "This dataset includes information about the applicant and defendant in each case. There is one observation per party per case. The dataset indicates the actor's name as recorded by the Court, the actor's role in the case (as applicant or defendant), and the type of the actor (member state, EU institution, company, individual etc.)." },
    { name: "decisions", label: "Decisions issued by the CJEU", description: "This dataset includes information on the universe of CJEU decisions, including judgments, orders, opinions of the Court, advocate-general opinions, and more. There is one observation per decision. The dataset includes the ECLI and CELEX numbers for each document, which allows you to look up the case in InfoCuria or EUR-Lex. It also indicates whether the proceeding is a direct action or a reference for a preliminary ruling." },
    { name: "assignments", label: "Assignments of judges to CJEU decisions", description: "This dataset includes information on which judges were assigned to the chamber that issued each decision. There is one observation per judge per decision. The dataset also indicates which judge served as the president (or acting president) of the chamber and which judge served as the judge-rapporteur (the judge who manages the proceeding and who drafts the opinion)." },
    { name: "procedures", label: "Legal procedures in CJEU decisions", description: "This dataset includes information on legal procedures referenced in CJEU decisions. There is one observation per legal procedure per decision. There are many legal procedures, but the major ones are references for preliminary rulings, actions for failure to fulfill obligations, actions for annulment, actions for failure to act, damages for non-contractual liability, and appeals. The dataset indicates the ruling of the Court with respect to each legal procedure. For direct actions (all legal procedures except references for a preliminary ruling), the Court can rule that the plaintiff's application was successful, unfounded, or inadmissible. It can also issue an interlocutory ruling or dismiss the application." },
    { name: "submissions", label: "Observations and interventions in CJEU decisions", description: "This dataset includes information on submissions by third parties referenced in CJEU decisions. There is one observation per submission per decision. Submissions include observations, oral observations, and interventions. The dataset indicates the type of the submission, type of actor responsible for the submission (member state, EU institution, company, individual, etc.), and the side (applicant or defendant) that the actor supported, if applicable." },
    { name: "citations", label: "Citations in CJEU decisions", description: "This dataset includes information on citations in CJEU decisions. There is one observation per unique citation per decision. It indicates each document that a decision cites, which paragraphs the citation occurs in, and which paragraphs and articles of the cited document are referenced (if applicable). The cited documents are identified by their CELEX numbers." },
    { name: "judges", label: "CJEU judges and Advocates General", description: "This dataset includes information on each judge and advocate-general who has served on CJEU. There is one observation per individual who has served as a judge or advocate-general. The dataset indicates each judge's member state, gender, appointment dates, and professional history, including whether each judge has prior experience as a judge, lawyer, politician, civil servant, or academic." },
  ],

  cjeuTextCorpus: [

  ],

  ungaVotingRecords: [

  ],

  unia: [
    { name: "unga_chairs", label: "Chairs of UNGA meetings", description: "This dataset includes data on chairs of UNGA meetings from 1994 to 2021. There is one observation per meeting per session. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset indicates the name and member state of each chair and whether the chair is the president of the UNGA." },
    { name: "unga_committee_records", label: "UNGA committee meeting records", description: "This dataset includes data on UNGA committee meeting records from 1976 to 2021. There is one observation per meeting record per committee. The source of the data is the UN Digital Library. There is no missing data for the covered period, conditional on the completeness of the records of the UN Digital Library. Information on committee meeting records prior to 1975 is not available. The dataset covers the six main committees: The Disarmament and International Security Committee; the Economic and Financial Committee; the Social, Humanitarian and Cultural Committee; the Special Political and Decolonization Committee; the Administrative and Budget Committee; and the Legal Committee. The dataset indicates the document ID number and date of each meeting record and the name of the committee. It also indicate whether the meeting record is a verbatim record or a summary record." },
    { name: "unga_decisions", label: "UNGA decisions without a vote", description: "This dataset includes data on decisions without a vote in UNGA meeting records from 1994 to 2021. There is one observation per decision without a vote per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset indicates the document ID number and date of each meeting record, the question that was put by the presiding officer, whether the decision was about the adoption of a draft resolution, and the document ID number of the draft resolution, if applicable." },
    { name: "unga_diplomats", label: "UNGA diplomats", description: "This dataset includes data on member state diplomas who make speeches in UNGA meeting records from 1994 to 2021. There is one observation per diplomat per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset indicates the name of the diplomat, the member state that the diplomat represented, the number of times the diplomat spoke during each meeting, and the total number of words in their speeches during each meeting." },
    { name: "unga_presidents", label: "Presidents of the UNGA", description: "This dataset includes data on presidents of the UNGA from 1994 to 2021. There is one observation per session. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset indicates the name and member state of each president." },
    { name: "unga_record_citations", label: "Citations in UNGA records", description: "This dataset includes data on citations in UNGA meeting records from 1994 to 2021. There is one observation per citation per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset indicates the document ID numbers for the citing document and the cited document. It indicates the type of the cited document. It also indicates the page, column, and line of each citation in the citing document." },
    { name: "unga_records", label: "UNGA meeting records", description: "This dataset includes data on UNGA meeting records from 1946 to 2021. There is one observation per meeting record. The source of the data is the UN Digital Library. There is no missing data. The dataset indicates the document ID number and date for each meeting record. It also indicates whether each record is for a meeting during an ordinary session, a special session, or an emergency special session." },
    { name: "unga_records_by_item", label: "Text of UNGA meeting records by item", description: "This dataset includes data on the text of UNGA meeting records from 1994 to 2021. There is one observation per item per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset includes the full text of each item in the meeting record and indicates the type of the item (i.e., an agenda item, a header, a speech paragraph, a recorded vote, a decision without a vote, etc.). It also indicates the page, column, and line of the item in the meeting record." },
    { name: "unga_records_by_line", label: "Text of UNGA meeting records by line", description: "This dataset includes data on the text of UNGA meeting records from 1994 to 2021. There is one observation per line per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset indicates the document ID number for each meeting record, the text of each line, and the location of each line in the document." },
    { name: "unga_resolutions", label: "UNGA resolutions", description: "This dataset includes data on UNGA resolutions from 1946 to 2021. There is one observation per draft resolution. The source of the data is the Dag Hammarskjöld Library at the UN. There is no missing data. The dataset includes the document ID number, date, and title of each draft resolution. It also indicates the corresponding draft resolution, the committee that considered the draft resolution (if applicable), and the number of member states that voted for and against the resolution." },
    { name: "unga_speeches", label: "UNGA speeches by paragraph", description: "This dataset includes data on speeches in UNGA meeting records from 1994 to 2021. There is one observation per paragraph per speech per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset includes the full text of each speech paragraph and indicates the name of the speaker, whether the speaker is the presiding officer, and the member state the speaker is representing (if applicable). It also indicates the page, column, and line of the speech paragraph in the meeting record." },
    { name: "unga_votes_in_records", label: "UNGA recorded votes", description: "This dataset includes data on recorded votes in UNGA meeting records from 1994 to 2021. There is one observation per recorded vote per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset indicates the ID number of the recorded vote and the outcome of the vote. It lists which member states voted yes, voted no, and abstained. If the vote was on the final passage of a draft resolution, it indicates the resolution number. It also indicates the page, column, and line of the recorded vote in the meeting record." },
    { name: "unga_votes_in_records_by_state", label: "UNGA recorded votes by member state", description: "This dataset includes data on recorded votes in UNGA meeting records from 1994 to 2021. There is one observation per member state state per recorded vote per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset indicates the ID number of the recorded vote and whether each member state voted yes, voted no, abstained, or did not participate in voting." },

    { name: "unsc_decisions", label: "UNSC decisions without a vote", description: "This dataset includes data on decisions without a vote in UNSC meeting records from 1994 to 2021. There is one observation per decision without a vote per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset indicates the document ID number and date of each meeting record, the question that was put by the presiding officer, whether the decision was about the adoption of a draft resolution, and the document ID number of the draft resolution, if applicable." },
    { name: "unsc_draft_resolution_citations", label: "Citations in UNSC draft resolutions", description: "This dataset includes data on citations in UNSC draft resolutions from 1994 to 2021. There is one observation per citation per draft resolution. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all draft resolutions that are available as a digital PDF from this source. The dataset indicates the document ID numbers for the citing document and the cited document. It indicates the type of the cited document. It also indicates the page, column, and line of each citation in the citing document." },
    { name: "unsc_draft_resolutions", label: "UNSC draft resolutions", description: "This dataset includes data on UNSC draft resolutions from 1946 to 2021. There is one observation per draft resolution. The source of the data is the UN Digital Library. There is no missing data, conditional on the completeness of the UN Digital Library records. The dataset indicates the document ID number, date, and title for each draft resolution." },
    { name: "unsc_draft_resolutions_by_line", label: "Text of UNSC draft resolutions by line", description: "This dataset includes data on the text of UNSC draft resolutions from 1994 to 2021. There is one observation per line per draft resolution. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all draft resolutions that are available as a digital PDF from this source. The dataset indicates the document ID number for each draft resolution, the text of each line, and the location of each line in the document." },
    { name: "unsc_draft_resolutions_by_paragraph", label: "Text of UNSC draft resolutions by paragraph", description: "This dataset includes data on the text of UNSC draft resolutions from 1994 to 2021. There is one observation per paragraph per draft resolution. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all draft resolutions that are available as a digital PDF from this source. The dataset indicates the document ID number for each draft resolution, the text of each paragraph, and the location of each paragraph in the document, whether the paragraph is part of the preamble or the operative part, and the word count of the paragraph." },
    { name: "unsc_letters", label: "UNSC letters of exchange", description: "This dataset includes data on UNSC letters of exchange, which are letters between the President of the Security Council and the Secretary-General, from 1997 to 2021. There is one observation per letter. The source of the data is the UN Security Council website. There is no missing data for the covered period. Data on letters of exchange from years prior to 1997 is not available from this source. There is no missing data for the covered period. There is no missing data for the covered period. The dataset indicates the document ID number, date, author (the President of the Security Council or the Secretary-General), and title of each letter." },
    { name: "UNSC member states", label: "UNSC member states", description: "This dataset includes data on UNSC member states from 1946 to 2021. There is one observation per state that has been elected to the Security Council. The source of the data is the UN Security Council website. There is no missing data. The dataset indicates the name and COW code of each member state, whether the member state is a permanent member or a rotating member, the years that each member state was elected to the Security Council, and the total number of years that the member state has been a member of the Security Council." },
    { name: "unsc_members_by_year", label: "UNSC member states by year", description: "This dataset includes data on UNSC member states from 1946 to 2021. There is one observation per member state per year. The source of the data is the UN Security Council website. There is no missing data. The dataset indicates the year, the name and COW code of each member state, the number of member states that were on the Security Council in that year, whether the member was a permanent member or a rotating member, and the document ID number for the meeting record containing the election of the member state to the Security Council." },
    { name: "unsc_presidential_statement_citations", label: "Citations in UNSC presidential statements", description: "This dataset includes data on citations in UNSC presidential statements from 1994 to 2021. There is one observation per citation per presidential statement. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all presidential statements that are available as a digital PDF from this source. The dataset indicates the document ID numbers for the citing document and the cited document. It indicates the type of the cited document. It also indicates the page, column, and line of each citation in the citing document." },
    { name: "unsc_presidential_statements", label: "UNSC presidential statements", description: "This dataset includes data on UNSC presidential statements, which are statements made by the President of the Security Council on behalf of the members, from 1994 to 2021. There is one observation per press statement. The source of the data is the UN Security Council website.  There is no missing data for the covered period. Data on press statements from years prior to 2001 is not available from this source. The dataset indicates the document ID number, date, and title of each presidential statement." },
    { name: "unsc_presidential_statements_by_line", label: "Text of UNSC presidential statements by line", description: "This dataset includes data on the text of UNSC presidential statements, which are statements made by the President of the Security Council on behalf of the members, from 1994 to 2021. There is one observation per line per presidential statement. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all presidential statements that are available as a digital PDF from this source. The dataset indicates the document ID number for each presidential statement, the text of each line, and the location of each line in the document." },
    { name: "unsc_presidential_statements_by_paragraph", label: "Text of UNSC presidential statements by paragaph", description: "This dataset includes data on the text of UNSC presidential statements, which are statements made by the President of the Security Council on behalf of the members, from 1994 to 2021. There is one observation per paragraph per presidential statement. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all presidential statements that are available as a digital PDF from this source. The dataset indicates the document ID number for each presidential statement, the text of each paragraph, and the location of each paragraph in the document, and the word count of the paragraph." },
    { name: "unsc_press_statements", label: "UNSC press statements", description: "This dataset includes data on UNSC press statements from 2001 to 2021. There is one observation per press statement. The source of the data is the UN Security Council website. There is no missing data for the covered period. Data on press statements from years prior to 2001 is not available from this source. There is no missing data for the covered period. The dataset indicates the document ID number, date, and title of each press statement." },
    { name: "unsc_record_citations", label: "Citations in UNSC records", description: "This dataset includes data on citations in UNSC meeting records from 1994 to 2021. There is one observation per citation per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset indicates the document ID numbers for the citing document and the cited document. It indicates the type of the cited document. It also indicates the page, column, and line of each citation in the citing document." },
    { name: "unsc_records", label: "UNSC meeting records", description: "This dataset includes data on UNSC meetings records from 1946 to 2021. There is one observation per meeting record. The source of the data is the UN Digital Library. There is no missing data. The dataset indicates the document ID number, date, and topic of each meeting. It also indicates whether each meeting record was for a resumed meeting or a closed meeting." },
    { name: "unsc_records_by_item", label: "Text of UNSC meeting records by item", description: "This dataset includes data on the text of UNSC meeting records from 1994 to 2021. There is one observation per item per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset includes the full text of each item in the meeting record and indicates the type of the item (i.e., a header, a speech paragraph, a recorded vote, a decision without a vote, etc.). It also indicates the page, column, and line of the item in the meeting record." },
    { name: "unsc_records_by_line", label: "Text of UNSC meeting records by line", description: "This dataset includes data on the text of UNSC meeting records from 1994 to 2021. There is one observation per line per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset indicates the document ID number for each meeting record, the text of each line, and the location of each line in the document." },
    { name: "unsc_reports", label: "UNSC reports of the Secretary-General", description: "This dataset includes data on UNSC reports of the Secretary-General from 1994 to 2021. There is one observation per report of the Secretary-General. The source of the data is the UN Security Council website. There is no missing data for the covered period. The dataset indicates the document ID number, date, and title of each report." },
    { name: "unsc_resolution_citations", label: "Citations in UNSC resolutions", description: "This dataset includes data on citations in UNSC resolutions from 1994 to 2021. There is one observation per citation per resolution. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all resolutions that are available as a digital PDF from this source. The dataset indicates the document ID numbers for the citing document and the cited document. It indicates the type of the cited document. It also indicates the page, column, and line of each citation in the citing document." },
    { name: "unsc_resolutions", label: "UNSC resolutions", description: "This dataset includes data on UNSC resolutions from 1946 to 2021. There is one observation per resolution. The source of the data is the UN Security Council website. There is no missing data. The dataset indicates the document ID number, date, and title of each resolution. The dataset includes the document ID number, date, and title of each draft resolution." },
    { name: "unsc_resolutions_by_line", label: "Text of UNSC resolutions by line", description: "This dataset includes data on the text of UNSC resolutions from 1994 to 2021. There is one observation per line per presidential statement. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all presidential statements that are available as a digital PDF from this source. The dataset indicates the document ID number for each resolution, the text of each line, and the location of each line in the document." },
    { name: "unsc_resolutions_by_paragraph", label: "Text of UNSC resolutions by paragraph", description: "This dataset includes data on the text of UNSC resolutions from 1994 to 2021. There is one observation per paragraph per presidential statement. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all presidential statements that are available as a digital PDF from this source. The dataset indicates the document ID number for each resolution the text of each paragraph, and the location of each paragraph in the document, and the word count of the paragraph." },
    { name: "unsc_speeches", label: "UNSC speeches by paragraph", description: "This dataset includes data on speeches in UNSC meeting records from 1994 to 2021. There is one observation per speech per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset includes the full text of each speech paragraph and indicates the name of the speaker, whether the speaker is the presiding officer, and the member state the speaker is representing (if applicable). It also indicates the page, column, and line of the speech paragraph in the meeting record." },
    { name: "unsc_vetoes", label: "UNSC vetoes", description: "This dataset includes data on UNSC vetoes from 1946 to 2021. There is one observation per veto by a permanent member of the Security Council. There can be multiple vetoes per recorded vote. Note that permanent members can vote no on a draft resolution without issuing a veto. The source of the data is the Dag Hammarskjöld Library at the UN. There is no missing data. The dataset indicates the date of the veto, the draft resolution, the document ID number of the meeting record for the meeting at which the veto took place, and the name and COW code for the member state that issued the veto." },
    { name: "unsc_votes_in_records", label: "UNSC recorded votes", description: "This dataset includes data on recorded votes on UNSC resolutions from 1994 to 2021. There is one observation per recorded vote per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset indicates the document ID number of the resolution or draft resolution, the date of the vote, whether the vote passed, how many voting members there were, the number of members that participated in the voting, the number of yes votes, the number of no votes, and the number of abstentions." },
    { name: "unsc_votes_in_records_by_state", label: "UNSC recorded votes by state", description: "This dataset includes data on recorded votes in UNSC meeting records from 1994 to 2021. There is one observation per member state per recorded vote per meeting record. The source of the documents that the data is extracted from is the UN Official Document System. The dataset covers all meeting records that are available as a digital PDF from this source. The dataset indicates the ID number of the recorded vote and whether each member state voted yes, voted no, abstained, or did not participate in voting." },
  ],

  cpp: [
    { name: "members", label: "House members", description: "This dataset includes one observation per member of the House of Commons. It includes all members who serve from the 38th Parliament (starting in 2004) through the 43rd Parliament (ongoing). Each member has a unique ID number that is used widely across the other datasets in the database. The dataset indicates which parliaments each member participated in. " },
    { name: "members_by_parliament", label: "House members by parliament", description: "This dataset includes one observation per member of the House of Commons per parliament. Members who were elected to multiple parliaments have multiple observations in the dataset. The dataset covers the 38th Parliament (starting in 2004) through the 43rd Parliament (ongoing). It includes information about the constituency that the member represents and the member's party. Note that many constituencies change between the 41st and 42nd Parliaments due to redistricting. As such, a member's constituency can vary across their observations. The same is true for the member's party for the small number of members who change parties." },
    { name: "constituencies", label: "House constituencies", description: "This dataset includes one observation per constituency (or riding) in the House of Commons from the 38th Parliament to the 43rd Parliament. There are more constituencies than seats in parliament because of redistricting between the 41st and 42nd Parliaments. Many ridings changed, but not all of them. Each constituency has a unique ID number. If the name of a constituency does not change after redistricting, it keeps the same ID number." },
    { name: "constituencies_by_parliament", label: "House constituencies by parliament", description: "This dataset includes one observation per constituency (or riding) in the House of Commons per parliament from the 38th Parliament to the 43rd Parliament. Note that the number of seats in parliament increased from 308 to 338 after redistricting between the 41st the 42nd Parliaments." },
    { name: "provinces", label: "Canadian provinces", description: "This dataset includes one observation per Canadian province. Electoral constituencies are nested within provinces. Each province has a unique ID number." },
    { name: "parties", label: "Political parties in the House of Commons", description: "This dataset includes one observation per party that won a seat in the House of Commons from the 38th Parliament to the 43rd Parliament. Note that this dataset does not include parties that competed for seats but did not win. Each party has a unique ID number." },
    { name: "parties_by_parliament", label: "Political parties in the House of Commons by parliament", description: "This dataset includes one observation per party per parliament from the 38th Parliament to the 43rd Parliament. Not all parties are represented in each parliament. The dataset also indicates how many members there are from each party in each parliament. Note that this value is different from the number of seats held by each party because of turnover during parliaments." },

    { name: "officers", label: "Officers in the House of Commons", description: "This dataset includes one observation per appointment as a parliamentary officer per parliament. Officers in the House of Commons include the speaker, the deputy speaker, the assistant deputy speaker, the chair of the Committee of the Whole, the deputy chair, and the assistant deputy chair. The dataset records the start and end date of each appointment." },
    { name: "leaders", label: "Party leaders in the House of Commons", description: "This dataset includes one observation per leadership appointment per parliament. Leadership positions in the House of Commons include party leader, House leader (the leader of the party in the House), chief whips, whips, caucus chairs, and (in some cases) deputy caucus chairs. The dataset records the start and end date of each appointment and whether the member was a member of the governing party or the opposition." },
    { name: "ministers", label: "Government ministers", description: "This dataset includes one observation per minister per portfolio per ministry. In each ministry, a minister can hold one or more portfolios concurrently. A member who holds multiple portfolios at the same time will have multiple observations in the dataset for the same ministry. This dataset covers the ministries of Martin (the 27th Ministry), Harper (the 28th Ministry), and Trudeau (the 29th Ministry). The dataset records the official name of each portfolio." },
    { name: "ministerial_shuffles", label: "Ministerial shuffles", description: "This dataset includes one observation per ministry per portfolio per ministerial shuffle. Each time the composition of the ministry changes, a new shuffle begins. The dataset records the date of the shuffle and the entire composition of the ministry at that time. There can be multiple observations for the same minister in the same shuffle when the minister holds multiple portfolios." },
    { name: "ministerial_portfolios", label: "Ministerial portfolios", description: "This dataset includes one observation per portfolio per ministry. It is common for the Prime Minister to add or remove portfolios during a parliament. This dataset records, for each ministry, the first and last shuffle that includes the portfolio and how many different ministers held the portfolio during that ministry." },

    { name: "elections", label: "Elections for the House of Commons", description: "This dataset includes one observation per parliamentary election. It covers general elections and by-elections from the general election on 28 June, 2004 (the general election before Martin became Prime Minister) to the most recent general election on 21 October 2019. The dataset records, for each election, how many races there were (one race per constituency in general elections), the number of candidates that participated in the election, the number of parties that participated in the election, and the number of parties that won seats in the election." },
    { name: "races", label: "Races in House elections", description: "This dataset includes one observation per race (one observation per constituency in general elections and one observation per vacancy in by-elections). For each race, the dataset records the number of candidates who contested the seat, the candidate who won, and whether the winning candidate was the incumbent." },
    { name: "candidates", label: "Candidates in House elections", description: "This dataset includes one observation per candidate per race per election. It includes an observation for every candidate who participated in a parliamentary election since the general election on 28 June, 2004. Candidates who have participated in multiple elections will have multiple observations. The dataset records the election and race the candidate participated in, the constituency, the name and party of the candidate, and the result with respect to the candidate (defeated, elected, or re-elected)." },

    { name: "calendar", label: "The calendar of the House of Commons", description: "This dataset includes one observation per sitting per session per parliament. It records the date of the sitting." },
    { name: "journal", label: "Items in the Journal", description: "This dataset includes one observation per item in the Journal. The Journal is the official records of events and decisions in the House. It includes a record of motions that do not require notification, divisions (roll-call votes), and voice votes. There are 80 different types of items. The dataset records the date of the item, the Order of Business and Subject of Business that the item falls under, the type of the item, the full text of the item, the member responsible for the item (if applicable), any keywords that the Library of Parliament has coded for the item, and the ID number of any bills that the item references." },
    { name: "notice_papers", label: "Items in the Notice Papers", description: "This dataset includes one observation per item in the Notice Paper per sitting. The Notice Paper is published every day there is a sitting and lists every item of business that has been notified. The Notice Paper appears at the end of the Order Paper. Items that appear on the Notice Paper include bills, motions, and written questions. If an item appears on multiple Notice Papers, it will have multiple observations. For each item, this dataset records the date of the Notice Paper, the date the item was notified (notified items appear on the Notice Paper for the next sitting), and text of the item, and the member who notified the item (if available)." },
    { name: "order_papers", label: "Items in the Order Papers", description: "This dataset includes one observation per item in the Order Paper per sitting. The Order Paper is published every day there is a sitting and is the official agenda for the sitting. It lists every item that can be considered during the sitting (as items have to be notified ahead of time). Items that appear on the Order Paper include bills, motions, and written questions. An item can appear on many consecutive Order Papers before it is actually considered. For each item, this dataset records the date of the Order Paper, the date the item was notified (notified items appear on the Notice Paper for the next sitting), and text of the item, and the member who notified the item (if available)." },
    { name: "sohb_items", label: "Items in the Status of House Business (SoHB)", description: "This dataset includes one observation per subitem in the Status of House Business (SoHB). The Status of House Business is published at the end of each session and includes a list of all of the items from the Order Papers that the House has considered, including bills, motions, and written questions. Items in the Status of House Business have one or more subitems that indicate events that occurred with respect to the item. For each subitem, this dataset records the heading that the item falls under, the text of the item, the text of the subitem, and the member responsible for the item (if applicable)." },

    { name: "hansard", label: "The Hansard (plenary minutes)", description: "This dataset includes one observation per paragraph per speech in the Hansard (or minutes) of the House of Commons. It includes the complete corpus of floor speeches from the 39th Parliament (starting in 2006) through the 1st session of the 43rd Parliament (over 88 million words of text). For each observation, the dataset records the time stamp, the Order of Business and Subject of Business in the Hansard that the speech falls under, whether the speech is a translation, whether the member giving the speech was the presiding officer, the full text of the paragraph, any keywords that the Library of Parliament has coded for the paragraph, and ID numbers for any bills that are referenced in the paragraph." },
    { name: "evidence", label: "The Evidence (committee minutes)", description: "This dataset includes one observation per paragraph per speech in the Evidence (or minutes) of committees in the House of Commons. It includes the complete corpus of committee speeches from the 39th Parliament (starting in 2006) through the 1st session of the 43rd Parliament (over 120 million words of text). For each observation, the dataset records the time stamp, whether the speech is a translation, whether the person giving the speech was a member or a witness, the full text of the paragraph, any keywords that the Library of Parliament has coded for the paragraph, and ID numbers for any bills that are referenced in the paragraph." },

    { name: "bills", label: "House and Senate bills", description: "This dataset includes one observation per bill that was introduced in the House of Commons or the Senate. For each bill, the dataset records the chamber that the bill was introduced in, the title of the bill, the type of bill (House Government Bill, House Private Members' Bill, etc.), the date the bill was introduced, the statute created by the bill (if it became law), and the member who sponsored the bill (only one member can sponsor a bill)." },
    { name: "bill_versions", label: "Versions of House and Senate bills", description: "This dataset includes one observation per version per bill. Bills can have multiple versions if the text changes during the legislative process. Possible versions include the version at first reading (when introduced), the version as amended by committee, the version as passed by the House, the version as passed by the Senate, and the version at Royal asset (if the bill becomes law)." },
    { name: "bill_events", label: "Events related to bills in the House of Commons and Senate", description: "This dataset includes one observation per event per bill. This dataset records every event that occurs with respect to a bill during the legislative process (there are over 50 unique types of events). The dataset indicates the date of the event, a description of the event, and the chamber that the event occurred in." },
    { name: "bill_texts", label: "Bill texts by clause", description: "This dataset includes one observation per paragraph per version per bill. This dataset records the complete text of every bill introduced in the House of Commons or the Senate from the 39th Parliament through the 1st Session of the 43rd Parliament. Bills are divided into sections and subsections. A subsection can have at most two paragraphs. It can have only procedural text or procedural text and amended text. The dataset records whether each subsection adds text to an existing clause of a statute, creates a new clause, repeals a clause, or replaces the text of the clause." },

    { name: "motions", label: "Motions in the House of Commons", description: "This dataset includes one observation per motion made in the House fo Commons. The dataset includes four types of motions that must be notified ahead of time and included in the Notice Paper: Government Motions, Motions for the Production of Papers, Private Members' Motions, and Ways and Means Motions. For each motion, the dataset indicates the topic of the motion, as recorded in the Notice Paper, the full text of the motion, and the member who made the motion." },
    { name: "motion_events", label: "Events relating to motions", description: "This dataset includes one observation per event per motion. This dataset records every event that occurs with respect to a motion (there are over 40 unique types of events), including any debate and votes on the motion. The dataset indicates the date of the event and includes a description of the event." },
    { name: "bill_motions", label: "Motions in the House of Commons to amend bills", description: "This dataset includes one observation per motion to amend a bill. Motions to amend bills are treated different than other types of motions and are not uniquely numbered. This dataset records, for each motion, the bill being amended, the full text of the amendment, information about the member who made the motion, and information about how the amendment would change the bill (whether it would add text, replace text, or delete text)." },

    { name: "written_questions", label: "Written questions in the House of Commons", description: "This dataset includes one observation per written question submitted in the House of Commons. Members can submit written questions to request information from the government. This dataset indicates the topic of the question, as recorded in the Notice Paper, the date the question was notified, the full text of the question, and information about the member who submitted the question." },
    { name: "written_question_events", label: "Events related to written questions", description: "This dataset includes one observation per event per question. This dataset records every event that occurs with respect to a written question (there are over 10 unique types of events), including whether the government tabled a response to the question. The dataset indicates the date of the event and includes a description of the event." },

    { name: "divisions", label: "Divisions (roll-call votes) in the House of Commons", description: "This dataset includes one observation per division. A division is a record vote or roll-call vote where the vote of every member is public. For each division, this dataset indicates what the division was about (a bill, a motion, etc.). If the division was about a bill, it indicates the ID number of the bill. The dataset also indicates the outcome of each division (agreed to or negatived) and the number of votes for (yea) and against (nay)." },
    { name: "votes", label: "Votes by member in divisions", description: "This dataset includes one observation per vote per division. In divisions, the vote of every member is public. This dataset indicates how every member voted in every division from the 39th Parliament through the 1st Session of the 43rd Parliament. It indicates whenever a member casts a paired vote, so those votes can be removed before analysis." },

    { name: "committees", label: "Committees in the House of Commons", description: "This dataset includes one observation per committee in the House of Commons. The dataset records the acronym for each committee, the full name of the committee, any previous names, whether the committee was a special committee or a standing committee, and the parliaments during which the committee existed." },
    { name: "committees_by_parliament", label: "Committees by parliament", description: "This dataset includes one observation per committee in the House of Commons per parliament. Committees that existed for multiple parliaments will have multiple observations. The dataset records the acronym for each committee, the full name of the committee, and whether the committee was a special committee or a standing committee." },
    { name: "committee_meetings", label: "Committee meetings", description: "This dataset includes one observation per meeting per committee per parliament. The dataset indicates the date of each committee meeting, public access to the committee meeting (i.e., whether the meeting was televised, whether it was in camera, whether only audio was made available, etc.), and the topics that were discussed in the meeting (as recorded on the committee website)." },
    { name: "committee_members", label: "Committee members", description: "This dataset includes one observation per member per committee per parliament. In the House of Commons, committees have a chair, multiple vice chairs, members, and many associated members. The dataset records the role of each member." },
    { name: "subcommittees", label: "Subcommittees in the House of Commons", description: "This dataset includes one observation per subcommittee in the House of Commons. The dataset records the acronym for each subcommittee, the full name of the subcommittee, any previous names, the committee that the subcommittee is a part of, and the parliaments during which the subcommittee existed." },
    { name: "subcommittees_by_parliament", label: "Subcommittees by parliament", description: "This dataset includes one observation per subcommittee in the House of Commons per parliament. Subcommittees that existed for multiple parliaments will have multiple observations. The dataset records the acronym for each committee, the full name of the committee, and the committee that the subcommittee is a part of." },
    { name: "subcommittee_meetings", label: "Subcommittee meetings", description: "This dataset includes one observation per meeting per subcommittee per parliament. The dataset indicates the date of each subcommittee meeting, public access to the subcommittee meeting (i.e., whether the meeting was televised, whether it was in camera, whether only audio was made available, etc.), and the topics that were discussed in the meeting (as recorded on the subcommittee website)." },
    { name: "subcommittee_members", label: "Subcommittee members", description: "This dataset includes one observation per member per subcommittee per parliament. In the House of Commons, subcommittees have a chair, multiple vice chairs, and members. The dataset records the role of each member." },
  ],
}

export { databases, datasets };

// React components
import React, { useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Route, Switch, useLocation } from "react-router-dom";

// material-ui components
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";

// components
import ParallaxBlock from "./ParallaxBlock";
import Footer from "./Footer";
import { scroll }  from "./Utilities";

// Google analytics
import ReactGA from "react-ga";

export default function ResearchPage (props) {

  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("UA-234919487-1");
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const data = [
    {
      id: "publications",
      level: 1,
      title: "Publications" ,
      class: "personal-block-color",
      image: "cover-image.jpg",
    },
    {
      id: "publication-10",
      level: 2,
      title: "The Political Economy of Noncompliance in Customs Unions",
      author: ["Joshua C. Fjelstul"],
      publication: ["Journal of Theoretical Politics"],
      abstract: "States create customs unions to accrue consumer welfare gains. Given incentives to cheat to protect domestic firms from foreign competition, they create regulatory regimes to manage noncompliance. I develop a formal model that explains how the politics of compliance in regulatory regimes systematically distorts the welfare gains that states accrue from developing customs unions. The model predicts that regulatory regimes are most effective at enforcing compliance (i.e., at reducing trade barriers) in sectors with intermediate levels of firm homogeneity in terms of productivity. In highly homogenous sectors, regulatory regimes are not effective because noncompliance is minimal enough that litigation is not cost-effective; in highly heterogenous sectors, regulatory regimes are not effective because courts, concerned about noncompliance with their rulings, are unlikely to rule against defendants, deterring plaintiffs from bringing cases. The model also predicts the downstream consequences for the performance of individual firms and consumer welfare.",
      citation: "Fjelstul, Joshua C. \"The Political Economy of Noncompliance in Customs Unions.\" Journal of Theoretical Politics. Forthcoming.",
      class: "personal-block-white",
      button_label: "Download article",
      button_link: "Fjelstul-JTP-2022.pdf",
    },
    {
      id: "publication-9",
      level: 2,
      title: "The Timely Administration of Justice: Using Computational Simulations to Evaluate Institutions Reforms at the CJEU",
      author: ["Joshua C. Fjelstul", "Matthew Gabel", "Clifford J. Carrubba"],
      publication: ["Journal of European Public Policy"],
      abstract: "The Court of Justice of the European Union (CJEU) faces a growing backlog of cases and delays in administering justice. Identifying the factors that prolong the resolution of cases is critical for designing successful institutional reforms. In this paper, we use a Bayesian model to identify the characteristics of cases, judges, and the Court's internal procedures that affect the duration of cases. Then, we run computational simulations that use our model to estimate the likely effect of potential institutional reforms aimed at reducing the Court's backlog. We offer policy recommendations based on our findings. Our methodology can be tailored to study the efficiency of other domestic and international courts.",
      citation: "Fjelstul, Joshua C., Matthew Gabel, and Clifford J. Carrubba. \"The Timely Administration of Justice: Using Computational Simulations to Evaluate Institutional Reforms at the CJEU.\" Journal of European Public Policy. Forthcoming.",
      class: "personal-block-shaded",
      button_label: "View article",
      button_link: "https://www.tandfonline.com/doi/full/10.1080/13501763.2022.2113115",
    },
    {
      id: "publication-8",
      level: 2,
      title: "The CJEU Database Platform: Decisions and Decision-Makers",
      author: ["Stein Arne Brekke", "Joshua C. Fjelstul", "Silje Synnøve Lyder Hermansen", "Daniel Naurin"],
      publication: ["Journal of Law and Courts"],
      abstract: "This article presents the CJEU Database Platform, which provides scholars with an extensive collection of easily accessible, research-ready data on the the universe of cases, decisions, and judges at the Court of Justice of the European Union (CJEU). The CJEU Database Platform provides a foundation for the broader CJEU Database currently being developed by The IUROPA Project, a multidisciplinary group of scholars researching judicial politics in the European Union (EU). In this article, we illustrate how the CJEU Database Platform opens the door to new areas of theoretical and empirical research on judicial politics in the EU.",
      citation: "Brekke, Stein Arne, Joshua C. Fjelstul, Silje Synnøve Lyder Hermansen, and Daniel Naurin. \"The CJEU Database Platform: Decisions and Decision-Makers.\" Journal of Law and Courts. Forthcoming.",
      class: "personal-block-white",
      button_label: "Download article",
      button_link: "Fjelstul-JLC-database.pdf",
    },
    {
      id: "publication-7",
      level: 2,
      title: "Explaining Public Opinion on the Enforcement of the Stability and Growth Pact during the European Sovereign Debt Crisis" ,
      author: ["Joshua C. Fjelstul"],
      publication: ["European Union Politics"],
      abstract: "The EU reformed the regulatory rules of the Eurozone in response to the European sovereign debt crisis, empowering the EU to more effectively enforce the Stability and Growth Pact (SGP), which is designed to prevent debt crises. Given recent empirical evidence that the EU’s willingness to enforce EU law depends on public opinion, under what conditions will EU residents view SGP enforcement as an effective way of tackling the crisis? I theorize how individuals will evaluate SGP enforcement and test my theory’s predictions using cross-national survey data from all Eurozone member states and Bayesian multi-level models. I find that respondents’ preferences over SGP enforcement depend on the interaction of their political support for the European Economic and Monetary Union and their member state’s noncompliance with the SGP criteria. Public buy-in for SGP enforcement is lower precisely when enforcement is most important.",
      citation: "Fjelstul, Joshua C. \"Explaining Public Opinion on the Enforcement of the Stability and Growth Pact during the European Sovereign Debt Crisis.\" European Union Politics 23(2): 192-211.",
      class: "personal-block-shaded",
      button_label: "View article",
      button_link: "https://journals.sagepub.com/doi/full/10.1177/14651165221075940",
    },
    {
      id: "publication-6",
      level: 2,
      title: "How the Chamber System at the CJEU Undermines the Consistency of the Court’s Application of EU Law",
      author: ["Joshua C. Fjelstul"],
      publication: ["Journal of Law and Courts"],
      abstract: "The Court of Justice of the European Union (CJEU) uses a chamber system to more efficiently decide cases. To what extent, and under what conditions, does the CJEU’s chamber system undermine the consistency of the Court’s application of EU law? This paper contributes to the literature on the internal organization of collegial courts by presenting a computational formal model that predicts (a) that hearing cases in smaller chambers undermines the consistency of the Court’s application of EU law and (b) that the magnitude of this effect is larger when judges’ preferences are more heterogenous and smaller when plaintiffs strategically bring cases. Based on these findings, I use machine learning and empirical data on CJEU judgments to estimate the degree to which we should expect the chamber system to undermine the consistency of the CJEU’s application of EU law in practice.",
      citation: "Fjelstul, Joshua C. \"How the Chamber System at the CJEU Undermines the Consistency of the Court's Application of EU Law.\" Journal of Law and Courts. Forthcoming.",
      class: "personal-block-white",
      button_label: "Download article",
      button_link: "Fjelstul-JLC-chambers.pdf",
    },
    {
      id: "publication-5",
      level: 2,
      title: "Improving the Efficiency of Pretrial Bargaining in Disputes over Noncompliance with International Law: Encouraging Evidence from the European Union",
      author: ["Sivaram Cheruvu", "Joshua C. Fjelstul"],
      publication: ["Journal of European Public Policy"],
      abstract: "International institutions can create pretrial bargaining procedures to resolve disputes over noncompliance with international law. In this article, we consider how international institutions can improve the efficiency of pretrial bargaining, thereby reducing the compliance deficit. We use a quasi-experimental research design and difference-in-differences (DD) estimators to analyze the effectiveness of a recent policy initiative in the European Union (EU) — called EU Pilot — that was designed to improve the efficiency of pretrial bargaining in the EU. By evaluating the effectiveness of EU Pilot, we provide new evidence of a proposed management school solution to noncompliance — proper rule interpretation. By preventing accidental noncompliance, EU Pilot allows the Commission and member states to more quickly process cases involving intentional noncompliance, improving the efficiency of pretrial bargaining in the EU.",
      citation: "Cheruvu, Sivaram, and Joshua C. Fjelstul. \"Improving the Efficiency of Pretrial Bargaining in Disputes over Noncompliance with International Law: Encouraging Evidence from the European Union.\" Journal of European Public Policy. Forthcoming.",
      class: "personal-block-shaded",
      button_label: "View article",
      button_link: "https://www.tandfonline.com/doi/full/10.1080/13501763.2021.1945130",
    },
    {
      id: "publication-4",
      level: 2,
      title: "European International Courts: The CJEU and the ECtHR",
      author: ["Clifford Carrubba", "Joshua C. Fjelstul"],
      publication: ["Book Chapter"],
      abstract: "Over the last several decades, national governments have increasingly been choosing to create adjudicatory bodies, or courts, to help ensure fidelity to the international agreements they sign. Two of the earliest — and most influential today — are the Court of Justice of the European Union (CJEU) and the European Court of Human Rights (ECtHR). In this essay, we discuss three central features of the literature. First, we review the core questions scholars of these two courts have asked. Second, we review the main empirical strategies employed to answer these questions. In doing so, we also review the primary challenges in doing empirical work on these courts. And finally, we discuss future directions for the literature in terms of data innovations, theory innovations, and connecting to the domestic courts literature.",
      citation: "Carrubba, Clifford and Joshua Fjelstul. 2021. \"European International Courts: The CJEU and the ECtHR.\" In High Courts in Global Perspective: Evidence, Methodologies, and Findings, eds. Nuno Garoupa, Rebecca Gill and Lydia Tiede. University of Virginia Press.",
      class: "personal-block-white",
      button_label: "View book",
      button_link: "https://www.upress.virginia.edu/title/5638",
    },
    {
      id: "publication-3",
      level: 2,
      title: "The Evolution of European Union Law: A New Dataset on the Acquis Communautaire",
      author: ["Joshua C. Fjelstul"],
      publication: ["European Union Politics"],
      abstract: "The European Union legal system is one of the most complex and sophisticated in the world. This article models the Acquis Communautaire (i.e. the corpus of European Union law) as a network and introduces the Evolution of European Union Law data set, which tracks connections between European Union primary law, European Union secondary law, European Union case law, national case law that applies European Union law, and national law that implements European Union law. It is the largest, most comprehensive data set on European Union law to date. It covers the entire history of the European Union (1951–2015), contains over 365,000 documents, and records over 900,000 connections between them. Legislative and judicial scholars can use this data set to study legislative override of the Court of Justice of the European Union, the implementation of European Union law, and other important topics. As an illustration, I use the data set to provide empirical evidence consistent with legislative override.",
      citation: "Fjelstul, Joshua C. 2019. \"The Evolution of European Union Law: A New Dataset on the Acquis Communautaire.\" European Union Politics 20(4): 670-691.",
      class: "personal-block-shaded",
      button_label: "View article",
      button_link: "https://journals.sagepub.com/doi/abs/10.1177/1465116519842947",
    },
    {
      id: "publication-2",
      level: 2,
      title: "Explaining Incompleteness and Conditionality in Alliance Agreements",
      author: ["Joshua C. Fjelstul", "Dan Reiter"],
      publication: ["International Interactions"],
      abstract: "What explains the form of international alliances? Conventional wisdom forecasts that audience cost-averse states like democracies demand that their alliances include conditions, such as limitations to specific regions, to reduce the likelihood of paying the audience costs of treaty violation. This paper builds on the conventional wisdom by proposing that democracies are especially likely to demand that an alliance include conditions that make the treaty more incomplete, that is, make the compliance requirements more ambiguous, in comparison to including conditions that do not make the treaty more incomplete. Treaty incompleteness gives leaders another tool to reduce domestic political audience cost risks. Incompleteness makes it easier for a leader to argue to a domestic political audience that a wider array of actions, including actions such as nonintervention on behalf of an embattled ally that other signatories might view as noncompliant, is actually compliant, reducing domestic audience costs for these actions. Examining all international alliances since 1816 using seemingly unrelated regression, the paper finds empirical support for the theory: alliances with democracies are more likely to have conditions making the treaty more incomplete, but not more likely to have conditions that do not make the treaty more incomplete.",
      citation: "Fjelstul, Joshua C. and Dan Reiter. 2019. \"Explaining Incompleteness and Conditionality in Alliance Agreements.\" International Interactions 45(6):976-1002.",
      class: "personal-block-white",
      button_label: "View article",
      button_link: "https://www.tandfonline.com/doi/full/10.1080/03050629.2019.1647838",
    },
    {
      id: "publication-1",
      level: 2,
      title: "The Politics of International Oversight: Strategic Monitoring and Legal Compliance in the European Union",
      author: ["Joshua C. Fjelstul", "Clifford J. Carrubba"],
      publication: ["American Political Science Review"],
      abstract: "States often violate international agreements, both accidentally and intentionally. To process complaints efficiently, states can create formal, pre-trial procedures in which governments can negotiate with litigants before a case ever goes to court. If disputes are resolved during pre-trial negotiations, it can be very difficult to tell what has happened. Are governments coming into compliance? If so, are they only doing so when they have accidentally committed a violation, or even when they are intentionally resisting? Or are challenges simply being dropped? This paper presents a formal model to address these questions. We develop our theory in the context of the European Union (EU). To test our model, we collect a new data set of over 13,000 Commission infringement cases against EU member states (2003-2013). Our results suggest that accidental and intentional noncompliance both occur, but that intentional noncompliance is more common in the EU. We find that the Commission is an effective, if imperfect, monitor and enforcer of international law. The Commission can correct intentional noncompliance, but not always. It strategically drops cases that it believes it is unlikely to win.",
      citation: "Fjelstul, Joshua C., and Clifford J. Carrubba. 2018. \"The Politics of International Oversight: Strategic Monitoring and Legal Compliance in the European Union.\" American Political Science Review 112(3): 429–445.",
      class: "personal-block-shaded",
      button_label: "View article",
      button_link: "https://www.cambridge.org/core/journals/american-political-science-review/article/abs/politics-of-international-oversight-strategic-monitoring-and-legal-compliance-in-the-european-union/FEB58DC5F83D2BF718F5943F70451F47",
    },
    {
      id: "media",
      level: 1,
      title: "Media" ,
      class: "personal-block-color",
      image: "cover-image.jpg",
    },
    {
      id: "media-3",
      level: 2,
      title: "Justice Dept. needed luck to win Mar-a-Lago case. It shouldn’t be that way.",
      author: ["Joshua C. Fjelstul"],
      publication: ["The Monkey Cage", "The Washington Post"],
      abstract: "Late Wednesday night, the U.S. Court of Appeals for the 11th Circuit issued an emergency ruling that allows the Department of Justice to continue its unprecedented investigation into former president Donald Trump’s improper handling of classified documents. The 11th Circuit’s ruling was issued by three judges — two of whom were Trump appointees. Who were these judges, and how were they chosen? And why did two Trump appointees side with the DOJ and overturn Cannon’s ruling?",
      citation: "Fjelstul, Joshua C., \"Justice Dept. needed luck to win Mar-a-Lago case. It shouldn’t be that way.\" The Monkey Cage, The Washington Post. September 23, 2022.",
      class: "personal-block-white",
      button_label: "View article",
      button_link: "https://www.washingtonpost.com/politics/2022/09/23/trump-maralago-documents-fbi-ruling/",
    },
    {
      id: "media-2",
      level: 2,
      title: "Ukraine wants a fast pass to EU membership. It's not that easy.",
      author: ["Joshua C. Fjelstul"],
      publication: ["The Monkey Cage", "The Washington Post"],
      abstract: "On Feb. 28, four days after Russia invaded Ukraine, Ukrainian President Volodymyr Zelensky formally submitted an application for EU membership. On March 1, Zelensky reiterated his appeal, in an emotional address from a bunker in Kyiv to an extraordinary session of the European Parliament. He asked the EU to \"prove that you are with us.\" The president of the European Commission — the EU's executive body — has endorsed Ukraine's membership, and a number of EU members have called on the EU to immediately make Ukraine a candidate for membership. Seeing this positive reaction, Georgia and Moldova also formally applied to join. But becoming an EU member involves more than encouragement from key officials. Here's what you need to know about the European Union — and the membership process.",
      citation: "Fjelstul, Joshua C., \"Ukraine wants a fast pass to EU membership. It’s not that easy.\" The Monkey Cage, The Washington Post. March 9, 2022.",
      class: "personal-block-shaded",
      button_label: "View article",
      button_link: "https://www.washingtonpost.com/politics/2022/03/09/ukraine-eu-membership/",
    },
    {
      id: "media-1",
      level: 2,
      title: "How the public evaluates enforcement of the Stability and Growth pact",
      author: ["Joshua C. Fjelstul"],
      publication: ["European Politics and Policy"],
      abstract: "The EU responded to the Eurozone crisis by recommitting to the Stability and Growth Pact – the Eurozone's legal limit on the size of member states' deficits and debts. But when do EU citizens support enforcement of these limits, and do patterns in public opinion make enforcement more or less likely? Drawing on new research, Joshua Fjelstul explains how EU citizens evaluate the policy of enforcement through the lens of their political commitment to the euro, and how patterns in public support make enforcement less likely.",
      citation: "Fjelstul, Joshua C., \"How the public evaluates enforcement of the Stability and Growth pact.\" European Politics and Policy (EUROPP), London School of Economics and Political Science (LSE). March 21, 2022.",
      class: "personal-block-white",
      button_label: "View article",
      button_link: "https://blogs.lse.ac.uk/europpblog/2022/03/21/how-the-public-evaluates-enforcement-of-the-stability-and-growth-pact/",
    },
    {
      id: "working-papers",
      level: 1,
      title: "Working Papers" ,
      class: "personal-block-color",
      image: "cover-image.jpg",
    },
    {
      id: "working-paper-1",
      level: 2,
      title: "Prosecutorial Discretion and the Rule of Law: Does Public Opposition to the EU Deter the Commission from Prosecuting Violations of EU law?",
      author: ["Joshua C. Fjelstul"],
      publication: ["Revise and Resubmit", "European Union Politics"],
      abstract: "The European Commission has considerable discretion over whether to investigate and prosecute European Union (EU) member states for violations of EU law. Is the Commission less likely to take action against a member state when there is more public opposition to the EU? In this paper, I assess how public support for the EU influences the behavior of the Commission across the EU’s three major noncompliance procedures: (1) the infringement procedure, (2) the state aid procedure, and (3) the technical regulations procedure. Using a collection of three new databases that capture all observable activity in these procedures, I find that, in the first two procedures, where the Commis- sion has to consider the strategic behavior of the Court of Justice of the European Union (CJEU), public opposition to the EU deters the Commission from prosecuting potential violations of EU law, and that the magnitude of this effect varies by Directorate-General (DG), creating a noncompliance deficit that is systematically larger in some member states, and in some policy areas, than others.",
      citation: "Fjelstul, Joshua C. \"Prosecutorial Discretion and the Rule of Law: Does Public Opposition to the EU Deter the Commission from Prosecuting Violations of EU Law?\" Working paper. Invited to revise and resubmit at European Union Politics.",
      class: "personal-block-white",
      button_label: "Download paper",
      button_link: "Fjelstul-prosecutorial-discretion.pdf",
    },
    {
      id: "working-paper-2",
      level: 2,
      title: "The Judicial Politics of Regional Economic Integration: How International Courts Deter Member State Noncompliance in Common Markets",
      author: ["Joshua C. Fjelstul"],
      publication: ["Working Paper"],
      abstract: "States create common markets to eliminate non-tariff barriers to trade, but have incentives to cheat to protect unproductive domestic firms from foreign competition. When are international courts effective at deterring the member states of common markets from incorrectly implementing common market rules? In this paper, I develop a formal model of rule implementation in common markets that captures the strategic interac- tion between a member state, a plaintiff, and an international court and that takes into account the strategic behavior of international courts — that courts are less likely to rule against member states when the cost of compliance is high. I derive the conditions under which international courts are effective at deterring incorrect implementation by member states. I test the primary observable implication of the model using novel data on the universe of opportunities for European Union (EU) member states to implement Single Market regulatory rules between 2004 and 2019.",
      citation: "Fjelstul, Joshua C. \"The Judicial Politics of Regional Economic Integration: How International Courts Deter Member State Noncompliance in Common Markets.\" Working paper.",
      class: "personal-block-shaded",
      button_label: "Download paper",
      button_link: "Fjelstul-noncompliance-common-markets.pdf",
    },
    {
      id: "working-paper-3",
      level: 2,
      title: "How Do Merit Commissions Affect Judicial Behavior? Evidence from the Court of Justice of the Euroepan Union",
      author: ["Sivaram Cheruvu", "Joshua C. Fjelstul", "Slije Synnøve Lyder Hermansen", "Daniel Naurin"],
      publication: ["Working Paper"],
      abstract: "Governments create monitoring entities to ensure that policymakers are working effectively. Monitors' control over retaining and selecting policymakers, however, may create incentives that lead to sub-optimal outcomes. One such entity is a merit commission (also known as a judicial council) that evaluates judges subject to retention and (or) selection. How do merit commissions affect judicial behavior? We construct a formal model arguing that lower competence incumbent judges only subject to merit retention will complete cases more quickly at the expense of quality opinion-writing, as their productivity is a signal of their effort investment to the commission. Con- versely, judges subject to merit selection and subsequent retention will not make the same tradeoff, as the commission's initial selection of them makes it more costly for the commission to negatively evaluate them upon retention. We provide evidence leveraging the Article 255 panel for (re)appointments to the Court of Justice of the European Union.",
      citation: "Cheruvu, Sivaram, Joshua C. Fjelstul, Silje Synnøve Lyder Hermansen, and Daniel Naurin. \"How Do Merit Commissions Affect Judicial Behavior? Evidence from the Court of Justice of the European Union.\" Working paper.",
      class: "personal-block-white",
      button_label: "Download paper",
      button_link: "Fjelstul-merit-commissions.pdf",
    },
    {
      id: "working-paper-4",
      level: 2,
      title: "Political Appointees and Prosecutorial Discretion: How the Political Leadership of the European Commission Influences the Management of Infringement Cases",
      author: ["Joshua C. Fjelstul", "Sivaram Cheruvu"],
      publication: ["Working Paper"],
      abstract: "How do the politics of appointment influence how prosecutors exercise their prosecutorial discretion? To what extent do the politics of appointment lead to political bias in the handling of cases? We theorize how the politics of appointment influence the behavior of prosecutors and test our theoretical predictions in the context of the EU using data on the management of legal cases brought by the Directorates-General (DGs) of the European Commission against European Union (EU) member states for violating EU law. Like domestic prosecutors, DGs have full discretion over whether to pursue cases. We use data on the universe of cases (2004–2022) from the European Union Infringement Procedure (EUIP) Database and introduce the European Commission Internal Organization (ECIO) Database, which includes comprehensive, up-to-date information about the political leadership of the DGs. We discuss the implications of our findings for prosecutorial independence, the ongoing rule of law crisis in the EU, and the severity of the EU’s compliance deficit.",
      citation: "Cheruvu, Sivaram, and Joshua C. Fjelstul. \"Political Appointees and Prosecutorial Discretion: How the Political Leadership of the European Commission Influences the Management of Infringement Cases.\" Working paper.",
      class: "personal-block-shaded",
      button_label: "Download paper",
      button_link: "Fjelstul-political-appointees.pdf",
    },
    {
      id: "working-paper-5",
      level: 2,
      title: "Do Administrative Deficits Cause Noncompliance with International Law?",
      author: ["Sivaram Cheruvu", "Joshua C. Fjelstul"],
      publication: ["Working Paper"],
      abstract: "What are the causes of noncompliance with international law? A rich scholarship argues that limitations in administrative capacity are one of these causes. We provide new quantitative evidence of this relationship. Employing a dataset of infringement cases the European Commission launched against member states and leveraging member state qualification in the FIFA World Cup and UEFA Euros as an exogenous source of variation in their administrative capacity, we use a difference-in-differences design to provide evidence that administrative deficits cause a substantial delay in Commission infringement cases, which worsens the EU’s compliance deficit.",
      citation: "Cheruvu, Sivaram, Joshua C. Fjelstul. \"Do Administrative Deficits Cause Noncompliance with International Law?\" Working paper.",
      class: "personal-block-white",
      button_label: "Download paper",
      button_link: "Fjelstul-administrative-deficits.pdf",
    },
    {
      id: "working-paper-6",
      level: 2,
      title: "Decision-Making in the United Nations General Assembly: A Comprehensive Database of Resolutions, Decisions, and Votes",
      author: ["Joshua C. Fjelstul", "Simon Hug", "Christopher Kilby"],
      publication: ["Working Paper"],
      abstract: "Existing databases for UNGA voting (e.g., Bailey et al. 2017) provide excellent coverage of United Nations General Assembly voting on resolutions that were adopted by roll call vote. These databases, however, have known limitations: They were built on historical data sets (e.g., the ICPSR dataset) that have varying coverage, definitions, and accuracy. They do not consistently cover votes on draft resolutions that failed, i.e., votes on resolutions that were not adopted, and votes on amendments, etc. They modify a country’s vote when the country requested this ex-post revision—but only in some cases. They do not cover resolutions adopted by consensus. In this paper, we present an updated data set to address these shortcomings and explore the implication for existing and future research. For older data, our update is based on resolving discrepancies between multiple available machine-readable sources by returning to the original meeting records and by hand-coding omitted votes. More recent data are extracted directly from machine-readable meeting records. The resulting database is more complete in terms of breadth of coverage and provides more detail, allowing researchers to customize what is included to more closely match their research question.",
      citation: "Fjelstul, Joshua C., Simon Hug, and Christopher Kilby. \"What can we learn by Collecting More Complete UNGA Voting Records?\" Working Paper.",
      class: "personal-block-shaded",
      button_label: "Contact Me",
      button_link: "mailto: joshua.fjelstul@unige.ch",
    },
    {
      id: "working-paper-7",
      level: 2,
      title: "Legal Tradition and the Jurisprudence of International Courts",
      author: ["Joshua C. Fjelstul"],
      publication: ["Working Paper"],
      abstract: "The Court of Justice of the European Union (CJEU) is one of the most and active courts in the world and has generated an enormous body of case law. The role of case law in the Court’s jurisprudence is puzzling, however. As the highest court of a civil law legal system, the CJEU does not adhere to the common law doctrine of stare decisis — of binding precedent. Nevertheless, the Court frequently cites its own case law. Why do civil law international courts, composed predominately of judges from countries with civil law legal systems, cite case law like common law constitutional courts? In this paper, I provide an explanation to this puzzle based on the legal tradition of judges: that common law judges cite more case law than civil law judges. I use data on the universe of CJEU judgments and Bayesian multi-level models to estimate the effect of common law judges on case law citations at the CJEU. ",
      citation: "Fjelstul, Joshua C. \"Legal Tradition and the Jurisprudence of International Courts.\" Working Paper.",
      class: "personal-block-white",
      button_label: "Contact Me",
      button_link: "mailto: joshua.fjelstul@unige.ch",
    },
    {
      id: "working-paper-8",
      level: 2,
      title: "The Internal Organization of the CJEU: Discretion, Complinace, and the Strategic Use of Chambers",
      author: ["Matthew Gabel", "Clifford J. Carrubba", "Joshua C. Fjelstul"],
      publication: ["Working Paper"],
      abstract: "Overseeing the complex legal system of the European Union (EU), the Court of Justice of the European Union (CJEU) is an extraordinarily productive international court. To help the Court manage its substantial caseload, member states have given it discretion over which cases it can hear in small Chambers. The rules governing the Court’s use of Chambers have evolved substantially over time. The Chambers System may have a significant impact on the application of EU law, but we know remarkably little about it. This paper provides the first comprehensive description of the rules governing the Court’s use of Chambers and their evolution. We assess the degree to which the Court has complied with these rules, evaluate the Court’s strategic use of Chambers, and simulate the Court’s workload under counterfactual rules to demonstrate the impact of the Chambers System on the efficacy of the Court.",
      citation: "Gabel, Matthew, Clifford J. Carrubba, and Joshua C. Fjelstul. \"The Internal Organization of the CJEU: Discretion, Compliance, and the Strategic Use of Chambers.\" Working paper.",
      class: "personal-block-shaded",
      button_label: "Contact Me",
      button_link: "mailto: joshua.fjelstul@unige.ch",
    },
    {
      id: "working-paper-9",
      level: 2,
      title: "The Political Economy of State Aid in the European Union",
      author: ["Joshua C. Fjelstul"],
      publication: ["Working Paper"],
      abstract: "Business regulations in the European Union (EU) are highly complex, making it costly for EU firms to accurately identify how investor-friendly their member state’s regulations are, which are often industry-specific. Regulatory uncertainty can deter firms from undertaking prospective capital investment projects by preventing them from accurately forecasting their value. Under what conditions can EU member states induce firms to accept projects that they would otherwise reject by resolving information asymmetries? In this paper, I present a formal model that identifies the conditions under which EU member states can promote capital investment by using state aid to credibly communicate to non-recipient firms how investor-friendly their regulations are. I use a new dataset of over 10,000 state aid awards granted in 2016 and 2017 to test the model’s main prediction: That aid-granting agencies can attract the same investment projects from non-recipient firms with smaller state aid awards when their member states are more sensitive to reputational costs stemming from misleading signals.",
      citation: "Fjelstul, Joshua C. \"The Political Economy of State Aid in the European Union.\" Working paper.",
      class: "personal-block-white",
      button_label: "Contact Me",
      button_link: "mailto: joshua.fjelstul@unige.ch",
    },
    {
      id: "working-paper-10",
      level: 2,
      title: "Measuring Public Position-Taking in Legislative Speeches Using Machine Learning",
      author: ["Joshua C. Fjelstul"],
      publication: ["Working Paper"],
      abstract: "This paper presents a widely-applicable methodology for scaling legislators using cutting- edge machine learning techniques. Based on the text of legislative speeches, it measures public position-taking by individual legislators on a government-opposition dimension. I use a deep convolutional neural network to predict whether paragraphs of legislative speeches were delivered by a member of the governing party (or coalition) as a function of the text of their speeches. I use the predicted probabilities to create paragraph-level scores and aggregate those scores to create time-varying and bill-specific measures of position-taking for individual legislators. Unlike other scaling methods, my technique learns complex, syntactical relationships between words that affect the meaning of political speech. I illustrate this method by scaling members of the Canadian House of Com- mons using a new corpus of over 280,000 parliamentary speeches spanning 2005-2019 (over 80 million words). I validate this approach using qualitative evidence, compare it to other popular text-based methods of scaling legislators (including Wordscores and Wordfish), and provide an empirical application to illustrate how scholars can use the measure to study disciplining by parties.",
      citation: "Fjelstul, Joshua C. \"Measuring Public Position-Taking in Legislative Speeches Using Machine Learning.\" Working paper.",
      class: "personal-block-shaded",
      button_label: "Contact Me",
      button_link: "mailto: joshua.fjelstul@unige.ch",
    },
  ];

  const sidebarItems = data.map((item, i) => {
    return <div className={item.level == 1 ? "personal-sidebar-section-text" : "personal-sidebar-subsection-text"} onClick={() => { scroll(item.id, -80) }}>
      {item.title}
    </div>
  });

  const sidebar =
  <div>
    <div className="personal-sidebar-header">
      Research
    </div>
    <div className="personal-sidebar-container">
      {sidebarItems}
    </div>
  </div>

  const contentItems = data.map((item, i) => {
    const title =
    <div className="personal-parallax-overlay">
      <div className="personal-parallax-title">
        {item.title}
      </div>
    </div>
    if (item.level == 1) {
      return <div id={item.id} key={i}>
        <ParallaxBlock path={item.image} content={title}/>
      </div>
    } else {
      return <div id={item.id} key={i} className={item.class}>
        <div className="personal-block-title">
          {item.title}
        </div>
        <div className="personal-chip-container">
          {item.publication.map((publication, i) => {
            return <Chip key={i} label={publication} className="personal-chip-color"/>
          })}
          {item.author.map((author, i) => {
            return <Chip key={i} label={author} className="personal-chip"/>
          })}
        </div>
        <div className="personal-block-paragraph">
          <span className="personal-block-abstract">Abstract.</span>{item.abstract}
        </div>
        <div className="personal-block-citation">
          {item.citation}
        </div>
        <div className="personal-block-content-centered">
          <div className="personal-button-container">
            <a className="personal-button-outlined" href={item.button_link} onClick={(e) => ReactGA.pageview(item.button_link)}>
              {item.button_label}
            </a>
          </div>
        </div>
      </div>
    }
  });

  const content =
  <div className="personal-page-content-container">
    {contentItems}
    <Footer />
  </div>

  return (
    <div className="personal-page-container">
      {sidebar}
      {content}
    </div>
  );
};

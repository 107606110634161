// React components
import React, { Component, useRef, useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
// import { Parallax as ParallaxContainer, ParallaxLayer, scrollTo } from "@react-spring/parallax";
// import { Parallax, ParallaxBanner } from "react-scroll-parallax";
import { Sticky, StickyContainer } from "react-sticky";

// material-ui components
// import Typography from "@material-ui/core/Typography";
import ParallaxBlock from "./ParallaxBlock";
// import ScrollToTopButton from "./ScrollToTopButton";
// import ArrowIcon from "@material-ui/icons/ArrowDownward";
// import SvgIcon from "@material-ui/core/SvgIcon";
import Footer from "./Footer";

// styles
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useSharedStyles from "./SharedStyles";

const useStyles = makeStyles((theme) => ({
  arrow: {
    bottom: 70,
    position: "absolute",
    color: "white",
    fontSize: 50,
    "-webkit-animation": "$bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95)",
    "animation": "$bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95)",
  },
  "@keyframes bounce": {
    "50%": {
      transform: "translateY(-15px)",
    }
  },
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function HomePage (props) {

  const classes = useStyles();

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
     function handleResize() {
       setWindowDimensions(getWindowDimensions());
     }

     window.addEventListener('resize', handleResize);
     return () => window.removeEventListener('resize', handleResize);
   }, []);

  const packages = [
    { name: "eumaps", status: "Coming soon", data: 0, description: "A package for making great-looking EU maps in just a few easy steps." },
    { name: "ggminimal", status: "On GitHub", data: 0, description: "A clean, minimalist ggplot theme that I use in my papers." },
    { name: "eulaw", status: "On GitHub", data: 0, description: "A package for accessing data from the eulaw.app API. Provides access to data from the EvoEU, ECIO, EUIP, EUSA, EUTR, and EUMS databases." },
    { name: "evoeu", status: "On GitHub", data: 1, description: "A data package with data from the Evolution of European Union Law (EvoEU) Database." },
    { name: "ecio", status: "On GitHub", data: 1, description: "A data package with data from the European Commission Internal Organization (ECIO) Database." },
    { name: "euip", status: "On GitHub", data: 1, description: "A data package with data from the European Commission Infringement Procedure (EUIP) Database." },
    { name: "eusa", status: "On GitHub", data: 1, description: "A data package with data from the European Union State Aid (EUSA) Database." },
    { name: "eutr", status: "On GitHub", data: 1, description: "A data package with data from the European Commission Technical Regulations (EUTR) Database." },
    { name: "eums", status: "On GitHub", data: 1, description: "A data package with data from the European Union Member States (EUMS) Database." },
    { name: "codebookr", status: "On GitHub", data: 0, description: "A package for making great-looking LaTeX codebooks and documentation for R data packages." },
    { name: "downloadr", status: "On GitHub", data: 0, description: "A simple, easy-to-use package for downloading files that keeps track of your progress." },
    { name: "cjeur", status: "Coming soon", data: 0, description: "A package for accessing data from the CJEU Database Platform: Decisions and Decision-Makers via the Iuropa API." },
    { name: "cjeuniv", status: "Coming soon", data: 1, description: "A package that provides a comprehensive list of CJEU cases and decisions and what sources they are available from." },
    { name: "cjeutools", status: "Coming soon", data: 0, description: "A package with tools for working with data on CJEU decisions, including functions for building citations and mapping ID numbers." },
  ];

  const title =
  <div className="personal-cover-image">
    <div className="personal-title">
      Joshua C.
    </div>
    <div className="personal-title">
      Fjelstul, Ph.D.
    </div>
    {/* <div className="personal-subtitle">
      Political scientist and data scientist
    </div> */}
    {/* <div className="personal-affiliation">
      Deparment of Political Science and International Relations, University of Geneva
    </div>
    <div className="personal-affiliation">
      ARENA Centre for European Studies, University of Oslo
    </div> */}
    <img src="downarrow.svg" width="60px" className={classes.arrow}/>
  </div>

  const image =
  <div className="personal-background-image">

  </div>

  return (
    <div className="personal-page-container">
      <ParallaxBlock height="100vh" path="cover-image.jpg" content={title}/>
      <div id="about-me" className="personal-block-shaded">
        <div className="personal-block-content-centered">
          <div className="personal-block-large-title">
            About Me
          </div>
        </div>
      </div>
      <div id="about-me" className = "personal-block-white">
        <div className="personal-block-paragraph">
          I'm a political scientist and data scientist. I'm a post-doctoral research fellow in the Department of Political Science and International Relations at the <span className="personal-bold">University of Geneva</span> and a researcher at the ARENA Centre for European Studies at the <span className="personal-bold">University of Oslo</span>. I was previously a post-doctoral research fellow at Washington Universitiy in St. Louis. I have a Ph.D. and M.A. in Political Science from Emory University (2019). I have a B.B.A. in the Canfield Business Honors Program from the McCombs School of Business at the University of Texas at Austin and a B.A. in Government and History from the College of Liberal Arts.
        </div>
        <div className="personal-block-paragraph">
          I study international political economy, international courts, and compliance with international law. I specialize in quantitative methods and data science, including causal inference, machine learning, text analysis, and data visualization. I also specialize in formal theory.
        </div>
        <div className="personal-block-paragraph">
          My research focuses on the politics of international and European economic integration, including the politics of the European Economic and Monetary Union (EMU), the politics of noncompliance with international law and European Union (EU) law and the impact on regional economic integration, and the institutional design of international courts, including the Court of Justice of the European Union (CJEU). My work uses advanced quantitative methods and game theory. I use causal inference and quasi-experimental techniques to evaluate the effectiveness of institutional reforms at international organizations, machine learning and quantitative text analysis to measure the political preferences of judges at international courts, and analytical and computational game theory to examine how international institutions shape the strategic behavior of states, organizations, and firms.
        </div>
        <div className="personal-block-paragraph">
          My research has appeared in leading international academic journals in political science and public administration, including the <a className="personal-link" href="https://www.cambridge.org/core/journals/american-political-science-review/article/abs/politics-of-international-oversight-strategic-monitoring-and-legal-compliance-in-the-european-union/FEB58DC5F83D2BF718F5943F70451F47">American Political Science Review</a> (APSR), the <a className="personal-link" href="https://www.tandfonline.com/doi/full/10.1080/13501763.2021.1945130">Journal of European Public Policy</a> (JEPP), the <a className="personal-link">Journal of Law and Courts</a> (JLC), the Journal of Thoretical Politics (JTP), <a className="personal-link" href="https://journals.sagepub.com/doi/abs/10.1177/1465116519842947">European Union Politics</a> (EUP), and <span className="personal-link">International Interactions</span> (II). I have written about my research in the <a className="personal-link" href="https://www.washingtonpost.com/politics/2022/03/09/ukraine-eu-membership/">Washington Post</a> and the <a className="personal-link" href="https://blogs.lse.ac.uk/europpblog/2022/03/21/how-the-public-evaluates-enforcement-of-the-stability-and-growth-pact/">European Politics and Policy (EUROPP)</a> blog from the London School of Economics and Political Science (LSE).
        </div>
      </div>
      <div className="personal-block-shaded">
        <div className="personal-block-content-centered">
          <div className="personal-block-large-title">
            Explore my work
          </div>
        </div>
      </div>
      <div id="research" className="personal-panel-grid">
        <div className="personal-panel-column">
          <StickyContainer style={{ height: windowDimensions.width > 768 ? "300vh" : "100%" }}>
            <Sticky>
              {({ style }) => (
                <div style={style}>
                  <div className="personal-panel-color">
                    Research
                  </div>
                </div>
              )}
            </Sticky>
          </StickyContainer>
        </div>
        <div className="personal-panel-column">
          <div className="personal-panel-white">
            <div className="personal-block-title">
              Publications
            </div>
            <div className="personal-block-paragraph">
              My research has appeared in leading political science and public administration journals, including the American Political Science Review (APSR), the Journal of European Public Policy (JEPP), the Journal of Law and Courts (JLC), the Journal of Theoretical Politics
              (JTP), European Union Politics (EUP), and International Interactions (II). I have published 9 journal articles and 1 book chapter.
            </div>
            <div className="personal-block-content-centered">
              <div className="personal-button-container">
                <Link className="personal-button-outlined" to="/research#publications">Learn more</Link>
              </div>
            </div>
          </div>
          <div className="personal-panel-shaded">
            <div className="personal-block-title">
              Media
            </div>
            <div className="personal-block-paragraph">
              I have written about my research for the Monkey Cage blog published by the Washington Post and for the European Politics and Policy (EUROPP) blog published by the London School of Economics and Politics Science (LSE).
            </div>
            <div className="personal-block-content-centered">
              <div className="personal-button-container">
                <Link className="personal-button-outlined" to="/research#media">Learn more</Link>
              </div>
            </div>
          </div>
          <div className="personal-panel-white">
            <div className="personal-block-title">
              Working Papers
            </div>
            <div className="personal-block-paragraph">
              My current research focuses on the internal politics of the Court of Justice of the European Union (CJEU), the enforcement of European Union (EU) law by the European Commission, and voting in the United Nations General Assembly (UNGA). I currently have 10 working papers.
            </div>
            <div className="personal-block-content-centered">
              <div className="personal-button-container">
                <Link className="personal-button-outlined" to="/research#working-papers">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="teaching" className="personal-panel-grid-flipped">
        <div className="personal-panel-column">
          <StickyContainer style={{ height: windowDimensions.width > 768 ? "300vh" : "100%" }}>
            <Sticky>
              {({ style }) => (
                <div style={style}>
                  <div className="personal-panel-color">
                    Teaching
                  </div>
                </div>
              )}
            </Sticky>
          </StickyContainer>
        </div>
        <div className="personal-panel-column">
          <div className="personal-panel-white">
            <div className="personal-block-title">
              Courses
            </div>
            <div className="personal-block-paragraph">
              My teaching interests include European and European Union (EU) politics, inernational political economy (IPE), international relations, political institutions, methodology, and game theory.
            </div>
            <div className="personal-block-paragraph">
              In the spring of 2020, I taught The Politics of the European Union at Washington University in St. Louis. In the fall of 2020, I taught International Political Economy, focusing on the politics of financial crises.
            </div>
            <div className="personal-block-paragraph">
              In the spring of 2016, I co-taught Emory University’s graduate-level Introduction to Game Theory course. I have also been a teaching assistant for Introduction to International Relations (undergraduate), Political Science Methods (undergraduate), and Introduction to Game Theory (graduate).
            </div>
            <div className="personal-block-content-centered">
              <div className="personal-button-container">
                <Link className="personal-button-outlined" to="/teaching#courses">Learn more</Link>
              </div>
            </div>
          </div>
          <div className="personal-panel-shaded">
            <div className="personal-block-title">
              Workshops
            </div>
            <div className="personal-block-paragraph">
              I have designed and facilitated <span className="personal-code">R</span> workshops on quantiative methods and quantitative text analysis for Graduate Institute of International and Development Studies (IHEID) in Geneva, the European Society of International Law (ESIL), the Institute for Quantitative Theory and Methods (QTM) at Emory University, and the Emory Center for Digital Scholarship (ECDS).
            </div>
            <div className="personal-block-content-centered">
              <div className="personal-button-container">
                <Link className="personal-button-outlined" to="/teaching#workshops">Learn more</Link>
              </div>
            </div>
          </div>
          <div className="personal-panel-white">
            <div className="personal-block-title">
              Tutorials
            </div>
            <div className="personal-block-paragraph">
              I wrote a tutorial on using regular expressions in <span className="personal-code">R</span> for the Graduate Institute for International and Development Studies (IHEID) in Geneva.
            </div>
            <div className="personal-block-content-centered">
              <div className="personal-button-container">
                <Link className="personal-button-outlined" to="/teaching#workshops">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="data" className="personal-panel-grid">
        <div className="personal-panel-column">
          <StickyContainer style={{ height: windowDimensions.width > 768 ? "500vh" : "100%" }}>
            <Sticky>
              {({ style }) => (
                <div style={style}>
                  <div className="personal-panel-color">
                    Data
                  </div>
                </div>
              )}
            </Sticky>
          </StickyContainer>
        </div>
        <div className="personal-panel-column">
          <div className="personal-panel-white">
            <div className="personal-block-title">
              The IUROPA CJEU Database
            </div>
            <div className="personal-block-paragraph">
              I created (with Stein Arne Brekke, Silje Synnøve Lyder Hermansen, and Daniel Naurin) the Court of Justice of the European Union (CJEU) Database Platform — a comprehensive database of CJEU decisions and decision-makers, and the core component of the IUROPA CJEU Database.
            </div>
            <div className="personal-block-paragraph">
              I also created (with Johan Lindholm, Daniel Naurin, and Michal Ovadek) the CJEU Document Corpus — another component of the IUROPA CJEU Database. The text corpus includes the French and English text of all CJEU decisions, including judgments, orders, and AG opinions.
            </div>
            <div className="personal-block-content-centered">
              <div className="personal-button-container">
                <Link className="personal-button-outlined" to="/data#IUROPA-CJEU-Database">Learn more</Link>
              </div>
            </div>
          </div>
          <div className="personal-panel-shaded">
            <div className="personal-block-title">
              European Union
            </div>
            <div className="personal-block-paragraph">
              I've created several major databases on the European Union (EU), including the Evolution of European Union Law (EvoEU) Database, the European Union Infringement Procedure (EUIP) Database, the European Union State Aid (EUSA) Database, the European Union Technical Regulations (EUTR) Database, and the European Commission Internal Organization (ECIO) Database.
            </div>
            <div className="personal-block-content-centered">
              <div className="personal-button-container">
                <Link className="personal-button-outlined" to="/data#European-Union">Learn more</Link>
              </div>
            </div>
          </div>
          <div className="personal-panel-white">
            <div className="personal-block-title">
              United Nations
            </div>
            <div className="personal-block-paragraph">
              I created (with Simon Hug and Christopher Kilby) the United Nations Generaly Assembly Decisions Database, which includes all decisions taken by the UNGA by recorded vote, non-recorded vote, and with without a vote (1946-2022).
            </div>
            <div className="personal-block-content-centered">
              <div className="personal-button-container">
                <Link className="personal-button-outlined" to="/data#United-Nations">Learn more</Link>
              </div>
            </div>
          </div>
          <div className="personal-panel-shaded">
            <div className="personal-block-title">
              Canadian Parliament
            </div>
            <div className="personal-block-paragraph">
              I created the Canadian Parliament Project (CPP) Database — the largest and most comprehensive research-oriented database about Canadian politics. The CPP Database includes 41 datasets that cover all aspects of parliamentary politics in the Canadian House of Commons.
            </div>
            <div className="personal-block-content-centered">
              <div className="personal-button-container">
                <Link className="personal-button-outlined" to="/data#Canadian-Parliament">Learn more</Link>
              </div>
            </div>
          </div>
          <div className="personal-panel-white">
            <div className="personal-block-title">
              The World Cup
            </div>
            <div className="personal-block-paragraph">
              I created the Fjelstul World Cup Database — a comprehensive database about the FIFA World Cup that covers all 21 World Cup tournaments (1930-2018). The database includes 27 datasets (approximately 1.1 million data points) that cover all aspects of the World Cup. It includes data on teams, players, managers, referees, matches, goals, cards, substitutions, and more.
            </div>
            <div className="personal-block-content-centered">
              <div className="personal-button-container">
                <Link className="personal-button-outlined" to="/data#World-Cup">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="software" className="personal-panel-grid-flipped">
        <div className="personal-panel-column">
          <StickyContainer style={{ height: windowDimensions.width > 768 ? "100vh" : "100%" }}>
            <Sticky>
              {({ style }) => (
                <div style={style}>
                  <div className="personal-panel-color">
                    Software
                  </div>
                </div>
              )}
            </Sticky>
          </StickyContainer>
        </div>
        <div className="personal-panel-column">
          <div className="personal-panel-white">
            <div className="personal-block-title">
              R Packages
            </div>
            <div className="personal-block-paragraph">
              I've developed and maintain several <span className="personal-code">R</span> packages, including <span className="personal-code">worldcup</span>, which provides access to data on the FIFA World Cup, <span className="personal-code">eumaps</span>, which includes tools for making maps of the EU, <span className="personal-code">iuropa</span>, which is an <span className="personal-code">R</span> interface to the <span className="personal-code">IUROPA API</span>, and <span className="personal-code">eulaw</span>, which is an <span className="personal-code">R</span> interface to the <span className="personal-code">eulaw.app API</span>.
            </div>
            <div className="personal-block-content-centered">
              <div className="personal-button-container">
                <Link className="personal-button-outlined" to="/software#R-packages">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer shadow={true}/>
    </div>
  );
};

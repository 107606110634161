// React components
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";

// Material-UI components
import App from './App';

// import components
import ScrollToTop from './ScrollToTop';

// CSS
import './index.css';

// JSS components
// import { ThemeProvider } from 'react-jss';

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

// theme
import theme from "./theme";

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <ParallaxProvider>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <App />
          </StyledEngineProvider>
        </ThemeProvider>
      </ParallaxProvider>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root')
);

// react components
import React, { useEffect } from "react";
import { useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

// google analytics
import ReactGA from 'react-ga';

// material-ui components
import useScrollTrigger from "@mui/material/useScrollTrigger";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Hidden from "@mui/material/Hidden";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Snackbar from "@mui/material/Snackbar";

// material-ui icons
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";

// material-ui styles
// import { withStyles, makeStyles } from "@material-ui/core/styles";

// components
import Footer from "./Footer";
// import Button from "./Button";

// pages
import HomePage from "./HomePage";
// import AboutPage from "./AboutPage";
import ResearchPage from "./ResearchPage";
import TeachingPage from "./TeachingPage";
import CongressionalTweetsPage from "./CongressionalTweetsPage";
import DatabasesPage from "./DatabasesPage";
// import DatabasePage from "./DatabasePage";
// import TextCorpusPage from "./TextCorpusPage";
// import { databases, datasets } from "./DataDescriptions";

// utilities
import ScrollToTop from "./ScrollToTop";
import SmoothScroll from "smoothscroll-polyfill";

// smooth scroll
SmoothScroll.polyfill();

function SetHeader () {
  const location = useLocation();
  if (location.pathname.includes("home")) {
    return("Home")
  } else if (location.pathname.includes("research")) {
    return("Research")
  } else if (location.pathname.includes("teaching")) {
    return("Teaching")
  } else if (location.pathname.includes("data")) {
    return("Data")
  } else if (location.pathname.includes("software")) {
    return("Software")
  } else {
    return("Home")
  }
}

function ShowMenuBar () {
  const location = useLocation();
  if (location.pathname.includes("congressional-tweets-project")) {
    return false;
  } else {
    return true;
  }
}

export default function App (props) {

  const [verticalMenuIsOpen, setVerticalMenuIsOpen] = useState(false);
  const [horizontalMenuIsOpen, setHorizontalMenuIsOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("UA-234919487-1");
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const menuDrawerVertical =
  <Drawer
    variant="temporary"
    anchor={"left"}
    open={verticalMenuIsOpen}
    elevation={0}
    classes={{paper: "menu-drawer-vertical"}}
    transitionDuration={500}
    onClose={() => setVerticalMenuIsOpen(false)}
    BackdropProps={{
      className: "drawer-backdrop",
    }}
    PaperProps={{
      className: "menu-drawer-vertical",
    }}
  >
    <Link className="menu-item" to="/#about-me" onClick={() => { setVerticalMenuIsOpen(false) }}>
      About Me
    </Link>
    <Link className="menu-item" to="/#research" onClick={() => { setVerticalMenuIsOpen(false) }}>
      Research
    </Link>
    <Link className="menu-item" to="/#teaching" onClick={() => { setVerticalMenuIsOpen(false) }}>
      Teaching
    </Link>
    <Link className="menu-item" to="/#data" onClick={() => { setVerticalMenuIsOpen(false) }}>
      Data
    </Link>
    {/* <Link className="menu-item" to="/#software" onClick={() => { setVerticalMenuIsOpen(false) }}>
      Software
    </Link> */}
  </Drawer>

  const menuDrawerHorizontal =
  <Drawer
    variant="temporary"
    anchor={"top"}
    open={horizontalMenuIsOpen}
    elevation={0}
    classes={{paper: "menu-drawer-horizontal"}}
    transitionDuration={500}
    onClose={() => setHorizontalMenuIsOpen(false)}
    BackdropProps={{
      className: "drawer-backdrop",
    }}
    PaperProps={{
      className: "menu-drawer-horizontal",
    }}
  >
    <Link className="menu-item" to="/#about-me" onClick={() => { setHorizontalMenuIsOpen(false) }}>
      About Me
    </Link>
    <Link className="menu-item" to="/#research" onClick={() => { setHorizontalMenuIsOpen(false) }}>
      Research
    </Link>
    <Link className="menu-item" to="/#teaching" onClick={() => { setHorizontalMenuIsOpen(false) }}>
      Teaching
    </Link>
    <Link className="menu-item" to="/#data" onClick={() => { setHorizontalMenuIsOpen(false) }}>
      Data
    </Link>
    {/* <Link className="menu-item" to="/#software" onClick={() => { setHorizontalMenuIsOpen(false) }}>
      Software
    </Link> */}
  </Drawer>

  const menuVertical =
  <div className="menu-vertical">
    <div className="menu-button-vertical" onClick={() => { verticalMenuIsOpen ? setVerticalMenuIsOpen(false) : setVerticalMenuIsOpen(true) }}>
      { verticalMenuIsOpen ? <CloseIcon className="menu-icon"/> : <MenuIcon className="menu-icon"/> }
    </div>
    <div className="media-icon-container-vertical">
      <a href="mailto: joshua.fjelstul@unige.ch">
        <EmailIcon className="media-icon"/>
      </a>
      <a href="http://www.github.com/jfjelstul">
        <GitHubIcon className="media-icon"/>
      </a>
      <a href="http://www.linkedin.com/in/jfjelstul">
        <LinkedInIcon className="media-icon"/>
      </a>
      <a href="http://www.twitter.com/joshfjelstul">
        <TwitterIcon className="media-icon"/>
      </a>
    </div>
  </div>

  var menuHorizontal =
  <div className="menu-horizontal">
    <div className="menu-left-container">
      <div className="menu-button-horizontal" onClick={() => { horizontalMenuIsOpen ? setHorizontalMenuIsOpen(false) : setHorizontalMenuIsOpen(true) }}>
        { horizontalMenuIsOpen ? <CloseIcon className="menu-icon"/> : <MenuIcon className="menu-icon"/> }
      </div>
      <div className="menu-title">
        {SetHeader()}
      </div>
    </div>
    <div className="media-icon-container-horizontal">
      <a href="mailto: joshua.fjelstul@unige.ch">
        <EmailIcon className="media-icon"/>
      </a>
      <a href="http://www.github.com/jfjelstul">
        <GitHubIcon className="media-icon"/>
      </a>
      <a href="http://www.linkedin.com/in/jfjelstul">
        <LinkedInIcon className="media-icon"/>
      </a>
      <a href="http://www.twitter.com/joshfjelstul">
        <TwitterIcon className="media-icon"/>
      </a>
    </div>
  </div>

  const placeholder =
  <div className="page">
    <div className="placeholder"></div>
    <Footer />
  </div>

  var banner =
  <div className="personal-site-banner">
    <Link className="personal-site-banner-title-container" to="/">
      <div className="personal-site-banner-title">
        Joshua C. Fjelstul, Ph.D.
      </div>
      <div className="personal-site-banner-subtitle">
        Political scientist and data scientist
      </div>
    </Link>
    <div className="personal-site-banner-button-container">
      {/* <a className="personal-site-banner-text-button" href="http://www.github.com/jfjelstul">
        View my portfolio
      </a> */}
      <a className="personal-site-banner-button" href="Fjelstul-CV.pdf" onClick={(e) => ReactGA.pageview("Fjelstul-CV.pdf")}>
        Download my CV
      </a>
    </div>
  </div>

  if (!ShowMenuBar()) {
    menuHorizontal = null;
    banner = null;
  }

  return (
    <div>
      <Switch>
        {/* <Route path="/" exact render={() => placeholder}/> */}

        <Route path="/" exact component={HomePage}/>
        <Route path="/research" exact component={ResearchPage}/>
        <Route path="/teaching" exact component={TeachingPage}/>
        <Route path="/data" exact component={DatabasesPage}/>

        <Route path="/congressional-tweets-project" exact component={CongressionalTweetsPage}/>

        {/* <Route path="/about" exact component={AboutPage}/>
        <Route path="/teaching" exact component={TeachingPage}/>
        <Route path="/data" exact component={DatabasesPage}/>
        <Route path="/text-corpus" exact component={TextCorpusPage}/>

        <Route path="/data/evoeu" exact render={() => <DatabasePage database={databases.evoeu} datasets={datasets.evoeu} GitHub={true}/> }/>
        <Route path="/data/ecio" exact render={() => <DatabasePage database={databases.ecio} datasets={datasets.ecio} GitHub={true}/> }/>
        <Route path="/data/euip" exact render={() => <DatabasePage database={databases.euip} datasets={datasets.euip} GitHub={true}/> }/>
        <Route path="/data/eusa" exact render={() => <DatabasePage database={databases.eusa} datasets={datasets.eusa} GitHub={true}/> }/>
        <Route path="/data/eutr" exact render={() => <DatabasePage database={databases.eutr} datasets={datasets.eutr} GitHub={true}/> }/>
        <Route path="/data/eums" exact render={() => <DatabasePage database={databases.eums} datasets={datasets.eums} GitHub={true}/> }/>

        <Route path="/data/cjeu-database-platform" exact render={() => <DatabasePage database={databases.cjeuDatabasePlatform} datasets={datasets.cjeuDatabasePlatform}/> }/>
        <Route path="/data/cjeu-text-corpus" exact render={() => <DatabasePage database={databases.cjeuTextCorpus} datasets={datasets.cjeuTextCorpus}/> }/>

        <Route path="/data/unga-voting-records" exact render={() => <DatabasePage database={databases.ungaVotingRecords} datasets={datasets.ungaVotingRecords}/> }/>
        <Route path="/data/unia" exact render={() => <DatabasePage database={databases.unia} datasets={datasets.unia}/> }/>

        <Route path="/data/cpp" exact render={() => <DatabasePage database={databases.cpp} datasets={datasets.cpp}/> }/> */}

      </Switch>
      {menuHorizontal}
      {menuDrawerHorizontal}
      {menuVertical}
      {menuDrawerVertical}
      {banner}
    </div>
  );
};

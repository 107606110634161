// styles
// import { useTheme, createUseStyles } from "react-jss";
import { makeStyles } from '@material-ui/core/styles';

const useAppStyles = makeStyles((theme) => ({

  //--------------------------------------------------
  // text
  //--------------------------------------------------

  text: {
    paddingTop: theme.textSpacing,
    paddingBottom: theme.textSpacing,
    fontSize: theme.fontSizeNormal,
    lineHeight: theme.lineHeight,
    color: theme.fontColorNormal,
    width: "100%",
  },

  textWhite: {
    extend: "text",
    color: theme.white,
  },

  title: {
    paddingTop: theme.textSpacing,
    paddingBottom: theme.textSpacing + 16,
    paddingLeft: "5%",
    paddingRight: "5%",
    color: theme.colorPrimary,
    fontSize: theme.fontSizeTitle,
    fontWeight: theme.fontWeightHeavy,
    textAlign: "center",
  },

  titleWhite: {
    extend: "title",
    color: theme.white,
  },

  subtitle: {
    extend: "title",
    fontSize: theme.fontSizeSubtitle,
    fontWeight: theme.fontWeightNormal,
  },

  subsubtitle: {
    paddingTop: theme.textSpacing + 16,
    paddingBottom: theme.textSpacing,
    fontSize: theme.fontSizeSubtitle,
    fontWeight: theme.fontWeightNormal,
    textAlign: "left",
  },

  dataset: {
    extend: "subtitle",
    fontFamily: "Roboto Mono",
  },

  header: {
    color: theme.fontColorNormal,
    fontSize: 70,
    fontWeight: theme.fontWeightHeavy,
    paddingBottom: 35,
    textAlign: "center",
  },

  subHeader: {
    color: theme.fontColorNormal,
    fontSize: 35,
    fontWeight: theme.fontWeightHeavy,
    textAlign: "center",
  },

  //--------------------------------------------------
  // general formatting
  //--------------------------------------------------

  page: {
    position: "absolute",
    left: 60,
    top: 0,
    right: 0,
  },

  rule: {
    width: "100%",
    border: "none",
    borderTop: "1px solid " + theme.lineColor,
    padding: 0,
    margin: 0,
    marginTop: 16,
    marginBottom: 16,
  },

  bold: {
    fontWeight: 500,
    color: theme.palette.custom.textDark,
  },

  italic: {
    fontStyle: "italic",
  },

  boldItalic: {
    fontWeight: 500,
    color: theme.palette.custom.textDark,
    fontStyle: "italic",
  },

  link: {
    color: theme.palette.primary.main,
    // textDecoration: "underline",
    cursor: "pointer",
    textDecoration: "none",
    fontWeight: 300,
    // borderBottomStyle: "solid",
    // borderWidth: 10,
    // borderColor: "rgba(75, 148, 230, 0.2)",
    // marginBottom: -10,
    boxShadow: "inset 0px -0px 0px white, inset 0px -8px 0px rgba(75, 148, 230, 0.12)",
  },

  linkWhite: {
    extend: "link",
    color: theme.white,
  },

  anchor: {
    marginTop: -200,
    paddingBottom: 200,
    display: "block",
  },

  inlineTitle: {
    color: theme.fontColorDark,
    fontWeight: theme.fontWeightNormal,
    fontSize: theme.fontSizeSubtitle,
    paddingRight: 24,
  },

  code: {
    backgroundColor: theme.palette.custom.codeBackground,
    borderRadius: 4,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 5,
    paddingRight: 5,
    color: theme.palette.custom.textCode,
    fontFamily: "Roboto Mono",
    fontSize: 14,
    fontWeight: 400,
  },

  quote: {
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 16,
    marginRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 16,
    fontWeight: 300,
    lineHeight: theme.lineHeight,
    // color: theme.palette.custom.textCode,
    color: "rgba(0, 0, 0, 0.5)",
    // backgroundColor: theme.palette.custom.codeBackground,
    // borderRadius: 8,
    borderStyle: "none",
    borderLeftStyle: "solid",
    borderWidth: 5,
    borderColor: theme.palette.custom.primary,
  },

  codeblock: {
    width: "90%",
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 16,
    marginRight: 16,
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 22,
    paddingRight: 22,
    lineHeight: theme.lineHeight,
    color: theme.palette.custom.textCode,
    backgroundColor: theme.palette.custom.codeBackground,
    borderRadius: 8,
    fontFamily: "Roboto Mono",
    fontWeight: 400,
    fontSize: 14,
    overflowX: "scroll",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.palette.custom.line,
  },

  spacer: {
    height: 16 * 2,
  },

  //--------------------------------------------------
  // panels
  //--------------------------------------------------

  panelRow: {
    display: "flex",
    flexDirection: "row",
  },

  panelColumn: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },

  panelHalfColor: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#4B94E6",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: 100,
    fontWeight: 800,
    fontFamily: "Roboto Condensed",
  },

  panelHalfWhite: {
    backgroundImage: "white",
    height: "100vh",
    paddingLeft: "15%",
    paddingRight: "15%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  panelHalfGradient: {
    backgroundImage: "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(250, 250, 250, 1))",
    height: "100vh",
    paddingLeft: "15%",
    paddingRight: "15%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  panelFullColor: {
    width: "100%",
    height: 225,
    backgroundColor: "#4B94E6",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: 80,
    fontWeight: 800,
    fontFamily: "Roboto Condensed",
  },

  panelFullWhite: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: "25%",
    paddingRight: "25%",
    paddingTop: 100,
    paddingBottom: 100,
  },

  panelFullGradient: {
    backgroundImage: "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(250, 250, 250, 1))",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: "25%",
    paddingRight: "25%",
    paddingTop: 100,
    paddingBottom: 100,
  },

  panelWideGradient: {
    backgroundImage: "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(250, 250, 250, 1))",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: "15%",
    paddingRight: "15%",
    paddingTop: 100,
    paddingBottom: 100,
  },

  panelFullGray: {
    backgroundColor: "rgba(245, 245, 245)",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "5%",
    paddingRight: "5%",
    paddingTop: 100,
    paddingBottom: 100,
  },

  contentRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 20,
  },

  contentRowCentered: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
  },

  //--------------------------------------------------
  // blocks
  //--------------------------------------------------

  block: {
    backgroundImage: "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(250, 250, 250, 1))",
    paddingTop: 100,
    paddingBottom: 100,
    paddingLeft: "15%",
    paddingRight: "15%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },

  blockShort: {
    extend: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },

  blockShaded: {
    extend: "block",
    backgroundColor: theme.backgroundColor,
  },

  blockColor: {
    extend: "block",
    backgroundColor: theme.primaryColor,
  },

  //--------------------------------------------------
  // buttons
  //--------------------------------------------------

  buttonContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
  },

  button: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 22,
    paddingRight: 22,
    color: theme.palette.custom.primary,
    borderColor: theme.palette.custom.primary,
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 50,
    fontWeight: 500,
    fontSize: 16,
    transitionDuration: "0.3s",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.custom.primary,
      color: "#FFFFFF",
    },
  },

  buttonWhite: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 22,
    paddingRight: 22,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderColor: "white",
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: 50,
    fontWeight: 500,
    fontSize: 16,
    transitionDuration: "0.3s",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "white",
      color: theme.palette.custom.primary,
    },
  },

  //--------------------------------------------------
  // parallax
  //--------------------------------------------------

  parallax: {
    height: 700,
    [theme.breakpoints.down("sm")]: {
      height: 500,
    },
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  parallaxBox: {
    backgroundColor: "rgba(27, 55, 86, 0.7)",
    width: "100%",
    position: "relative",
    zIndex: 100,
  },

  parallaxBoxContent: {
    paddingTop: 32,
    paddingBottom: 32,
    paddingLeft: "15%",
    paddingRight: "15%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },

  parallaxHeader: {
    color: "#FFFFFF",
    fontFamily: "Roboto Condensed",
    fontWeight: 700,
    marginTop: -8,
    marginBottom: 12,
    fontSize: 60,
    [theme.breakpoints.down("sm")]: {
      marginTop: -4,
      fontSize: 30,
    },
  },

  parallaxSubheader: {
    fontWeight: 300,
    fontSize: 20,
    color: "#FFFFFF",
    fontFamily: "Roboto Condensed",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },

  parallaxCaption: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: 32,
    minHeight: 32,
    color: "white",
    opacity: 0.7,
    fontSize: 14,
    fontWeight: 300,
  },

  parallaxLogo: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(75, 148, 230, 0.4)",
    marginBottom: -32,
  },

  //--------------------------------------------------
  // drawers
  //--------------------------------------------------

  drawerVertical: {
    height: "90vh!important",
    boxShadow: theme.shadow + "!important",
  },

  drawerHorizontal: {
    height: "60vw!important",
    boxShadow: theme.shadow + "!important",
  },

  drawerBackdrop: {
    backgroundColor: "rgba(0, 0, 0, 0.3)!important",
  },

  //--------------------------------------------------
  // cards
  //--------------------------------------------------

  cardPage: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.custom.background,
    position: "relative",
    paddingBottom: 64,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 32,
    },
  },

  cardPageHeader: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    width: "100vw",
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.1)",
    position: "relative",
    zIndex: 10,
  },

  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "25px",
    marginBottom: "25px",
    marginLeft: "25px",
    marginRight: "25px",
    width: 400,
    borderRadius: 16,
    [theme.breakpoints.down("sm")]: {
      marginTop: 32,
      marginLeft: 16,
      marginRight: 16,
      width: "unset",
    },
    transitionDuration: "0.5s",
    transition: "box-shadow",
    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      // opacity: 0.5,
      boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.2)",
    },
  },

  cardHeader: {
    backgroundColor: theme.palette.custom.primary,
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 22,
    paddingBottom: 10,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },

  card: {
    "&:hover": {
      "& $cardImage": {
        transform: "scale(1.05)",
      },
    },
  },

  cardImage: {
    transitionDuration: "0.5s",
    webkitBackfaceVisibility: "hidden",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingTop: 16,
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 32,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    backgroundColor: "#FFFFFF",
    position: "relative",
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },

  cardTitle: {
    color: theme.palette.custom.textDark,
    fontFamily: "Roboto Condensed",
    fontSize: 24,
    fontWeight: 600,
    paddingBottom: 10,
  },

  //--------------------------------------------------
  // chips
  //--------------------------------------------------

  chip: {
    display: "block",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: "rgb(245, 245, 245)",
    borderRadius: 50,
    color: theme.palette.custom.textLight,
    fontSize: 14,
    fontWeight: 300,
  },

  coloredChip: {
    extend: "chip",
    backgroundColor: theme.primaryColor,
    color: theme.white,
    fontWeight: theme.fontWeightNormal,
  },

  chipBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    paddingTop: 16,
  },

  chipContainer: {
    padding: 0,
    paddingRight: 16,
    paddingBottom: 16,
  },

}));

export default useAppStyles;

import React, { Component } from 'react'
import { useState } from 'react';
import { Parallax, Background } from "react-parallax"
import Typography from '@material-ui/core/Typography'

// styles
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useSharedStyles from './SharedStyles';

export default function ParallaxBlock (props) {

  var { path, content, height } = props;

  return(
    <Parallax
      blur={0}
      strength={400}
      bgImage={path}
      bgImageStyle={{ objectFit: "cover" }}
    >
      <div className="personal-parallax" style={{height: height}}>
        {content}
      </div>
    </Parallax>
  )
}
